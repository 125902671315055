export const SHOW_UID_TO_NAME = {
  'a-simple-space': 'A Simple Space',
  backbone: 'Backbone',
  'out-of-chaos': 'Out Of Chaos',
  'the-pulse': 'The Pulse',
  'i-want-to-touch-you': 'I Want To Touch You',
  'the-mirror': 'The Mirror',
  macro: 'MACRO',
  'ten-thousand-hours': 'Ten Thousand Hours',
};
