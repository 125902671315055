import React from 'react';
import classnames from 'classnames';

type CardGroupProps = {
  children: React.ReactNode | null;
  columns?: number;
};

const CardGroup = (props: CardGroupProps) => {
  const { children, columns = 3 } = props;

  return (
    <div className={classnames('card-group', `card-group--${columns}-column`)}>
      {children}
    </div>
  );
};

export default CardGroup;
