import { createConsistentDate } from './create-consistent-date';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Formats a date like "March 11" or "March 11 - 12" or "March 11 - April 3"
 * @param startDate date in the yyyy-mm-dd format
 * @param endDate date in the yyyy-mm-dd format
 * @returns
 */
export const getFormattedDate = (startDate: string, endDate: string | null) => {
  try {
    if (
      startDate.length !== 10 ||
      startDate[4] !== '-' ||
      startDate[7] !== '-'
    ) {
      return startDate;
    }
    const startDateObject = createConsistentDate(startDate);

    const startDayOfMonth = startDateObject.getDate();
    const startMonthName = monthNames[startDateObject.getMonth()];
    const startDateText = `${startMonthName} ${startDayOfMonth}`;

    if (!endDate) {
      // Returns date like March 11
      return startDateText;
    }

    const endDateObject = createConsistentDate(endDate);
    const endDayOfMonth = endDateObject.getDate();
    const endMonthName = monthNames[endDateObject.getMonth()];

    if (endMonthName === startMonthName) {
      // Returns date like March 11 - 12
      return `${startDateText} - ${endDayOfMonth}`;
    }

    // Returns date like March 11 - April 1
    return `${startDateText} - ${endMonthName} ${endDayOfMonth}`;
  } catch (error) {
    console.error(error);
    return startDate;
  }
};
