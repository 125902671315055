import * as React from 'react';
import { graphql } from 'gatsby';

import CardGroup from '../components/CardGroup';
import LinkCard from '../components/LinkCard';
import { IGatsbyImageData } from 'gatsby-plugin-image';

type PropTypes = {
  slice: {
    primary: {
      number_of_columns: number;
    };
    items: {
      image: {
        gatsbyImageData: IGatsbyImageData;
      };
      link: {
        url?: string;
        uid: string;
        document?: {
          data: {
            parent_page?: {
              uid: string;
            };
          };
        };
      };
      text: {
        text: string;
      };
    }[];
    slice_type: string;
  };
};

const getLink = (card: PropTypes['slice']['items'][0]): string => {
  const link = card.link;
  if (!link?.uid) {
    // This handles links that go to a non-Prismic page
    return link?.url || '';
  }

  const parentPageUid = link.document?.data?.parent_page?.uid;

  return parentPageUid ? `/${parentPageUid}/${link.uid}` : `/${link.uid}`;
};

export const LinkCards = ({ slice }: PropTypes) => {
  return (
    <section className="prismic-link-cards">
      <CardGroup columns={slice.primary.number_of_columns}>
        {slice.items.map((card) => {
          return (
            <LinkCard
              title={card.text.text}
              image={card.image.gatsbyImageData}
              link={getLink(card)}
              key={card.text.text}
            />
          );
        })}
      </CardGroup>
    </section>
  );
};

export const query = graphql`
  fragment PageDataBodyLinkCards on PrismicPageDataBodyLinkCards {
    id
    slice_type
    primary {
      number_of_columns
    }
    items {
      image {
        gatsbyImageData(height: 250)
      }
      link {
        uid
        url
        document {
          ... on PrismicPage {
            data {
              parent_page {
                uid
              }
            }
          }
        }
      }
      text {
        text
      }
    }
  }
`;
