import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PrismicTeamMember from '../components/PrismicTeamMember';
import PrismicTeamMemberModal from '../components/PrismicTeamMemberModal';
import { PrismicTeamMember as PrismicTeamMemberType } from '../types';

type PropTypes = {
  slice: {
    id: string;
    slice_type: string;
    items: PrismicTeamMemberType[];
  };
};

export const TeamMembers = ({ slice }: PropTypes) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentTeamMember, setCurrentTeamMember] =
    useState<PrismicTeamMemberType | null>(null);

  const handleMoreInfoClick = (member: PrismicTeamMemberType) => {
    setModalOpen(true);
    setCurrentTeamMember(member);
  };

  return (
    <section className="prismic-section-contained team-members-wrapper">
      {slice.items.map((member) => {
        return (
          <PrismicTeamMember
            key={member.name.text}
            member={member}
            handleMoreInfoClick={handleMoreInfoClick}
          />
        );
      })}
      <PrismicTeamMemberModal
        open={modalOpen}
        member={currentTeamMember}
        onClose={() => setModalOpen(false)}
      />
    </section>
  );
};

export const query = graphql`
  fragment PageDataBodyTeamMembers on PrismicPageDataBodyTeamMembers {
    id
    slice_type
    items {
      bio {
        richText
      }
      headshot {
        gatsbyImageData(width: 150, height: 150, layout: CONSTRAINED)
      }
      name {
        text
      }
      role {
        text
      }
    }
  }
`;
