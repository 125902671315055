import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

type AwardImagesProps = {
  images: IGatsbyImageData[];
};

const AwardImages = ({ images }: AwardImagesProps) => {
  return (
    <div className="awards-images">
      {images.map((image, i) => (
        <GatsbyImage
          image={image}
          key={i}
          className="awards-images__image"
          alt="awards-image"
          objectFit="contain"
        />
      ))}
    </div>
  );
};

export default AwardImages;
