import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { MdClose, MdChevronLeft, MdChevronRight } from 'react-icons/md/';
import { GalleryImage } from '../types';

type ImageGalleryModalProps = {
  open: boolean;
  images: GalleryImage[];
  currentImageIndex: number;
  onClose: () => void;
  prevImage: () => void;
  nextImage: () => void;
};

const ImageGalleryModal = (props: ImageGalleryModalProps) => {
  const { open, images, currentImageIndex, onClose, prevImage, nextImage } =
    props;
  const currentImage = images[currentImageIndex];

  const handleKeyEvent = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        // escape key
        onClose();
      } else if (event.keyCode === 37) {
        // left key
        prevImage();
      } else if (event.keyCode === 39) {
        // right key
        nextImage();
      }
    },
    [onClose, prevImage, nextImage]
  );

  const aL = useRef<HTMLDivElement>(null);
  const aR = useRef<HTMLDivElement>(null);
  const imageContainer = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(
    (e) => {
      if (
        open &&
        !imageContainer.current?.contains(e.target) &&
        !aL.current?.contains(e.target) &&
        !aR.current?.contains(e.target)
      ) {
        onClose();
      }
    },
    [onClose, open]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('keydown', handleKeyEvent);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('keydown', handleKeyEvent);
    };
  }, [handleClick, handleKeyEvent]);

  return (
    <div className={`image-gallery-modal__overlay ${open ? 'visible' : ''}`}>
      {open && (
        <Fragment>
          <div ref={imageContainer} className="image-container">
            <GatsbyImage
              image={currentImage.gatsbyImageData}
              className="gallery-image"
              imgStyle={{
                objectFit: 'contain',
                boxShadow: '0 5px 50px black',
              }}
              alt=""
            />
            <p className="image-credit">Photo Credit - {currentImage.credit}</p>
          </div>
          <div className="close-container">
            <MdClose size="2em" onClick={onClose} />
          </div>
          <div ref={aL} className="arrow arrow--left">
            <MdChevronLeft size="3em" onClick={prevImage} />
          </div>
          <div ref={aR} className="arrow arrow--right">
            <MdChevronRight size="3em" onClick={nextImage} />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default ImageGalleryModal;
