import React from 'react';
import ReactDOM from 'react-dom';
import whatInput from 'what-input';
import { PrismicProvider, PrismicToolbar } from '@prismicio/react';
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';
import * as prismic from '@prismicio/client';

import { linkResolver } from './src/linkResolver';
import PageTemplate from './src/pages/{PrismicPage.url}';

if (process.env.NODE_ENV !== 'production') {
  // const axe = require('@axe-core/react');
  // axe(React, ReactDOM, 1000);
}

const repositoryName = process.env.GATSBY_PRISMIC_REPO_NAME;
const prismicClient = prismic.createClient(repositoryName, {
  // This defines how you will structure URL paths in your project.
  // Update the types to match the custom types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.
  routes: [],
});

export const wrapRootElement = ({ element }) => {
  return (
    // <PrismicPreviewProvider
    //   repositoryConfigs={[
    //     {
    //       repositoryName,
    //       routeResolver: linkResolver,
    //       componentResolver: componentResolverFromMap({
    //         page: PageTemplate,
    //       }),
    //     },
    //   ]}
    // >
    <PrismicProvider client={prismicClient}>
      {element}
      <PrismicToolbar repositoryName={repositoryName} />
    </PrismicProvider>
    // </PrismicPreviewProvider>
  );
};
