import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { MdClose } from 'react-icons/md/';
import { PrismicRichText } from '@prismicio/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PrismicTeamMember as PrismicTeamMemberType } from '../types';

type PrismicTeamMemberModalProps = {
  member: PrismicTeamMemberType | null;
  open: boolean;
  onClose: () => void;
};

const TeamMemberModal = (props: PrismicTeamMemberModalProps) => {
  const { member, open, onClose } = props;
  const node = useRef<HTMLDivElement>(null);

  const handleKeyEvent = useCallback(
    (event) => {
      // if the key pressed is the escape key
      if (event.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  const handleClick = useCallback(
    (event) => {
      const isOutsideModal =
        !node.current || !node.current.contains(event.target);
      if (open && isOutsideModal) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('keydown', handleKeyEvent);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('keydown', handleKeyEvent);
    };
  }, [handleKeyEvent, handleClick]);

  return (
    <div className={`team-member-modal__overlay ${open ? 'visible' : ''}`}>
      {open && member && (
        <Fragment>
          <div className="team-member-modal__wrapper" ref={node}>
            <div className="team-member-modal__wrapper-inner">
              <div className="team-member-modal__top-wrapper">
                <GatsbyImage
                  image={member.headshot.gatsbyImageData}
                  className="team-member-modal__avatar"
                  alt={member.name.text}
                />
                <div className="team-member-modal__info">
                  <h3 className="team-member-modal__name">
                    {member.name.text}
                  </h3>
                  <div className="team-member-modal__position">
                    {member.role.text}
                  </div>
                </div>
              </div>
              <div className="team-member-dialog__bottom-wrapper">
                <PrismicRichText field={member.bio.richText} />
              </div>
            </div>
            <div className="close-button-container">
              <MdClose size="2em" onClick={onClose} />
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default TeamMemberModal;
