import { AwardsImages } from './AwardsImages';
import { ImageGallery } from './ImageGallery';
import { LinkCards } from './LinkCards';
import { Quotes } from './Quotes';
import { Seasons } from './Seasons';
import { SpecialSection } from './SpecialSection';
import { Text } from './Text';
import { TeamMembers } from './TeamMembers';
import { VideoLoop } from './VideoLoop';

export const components = {
  awards_images: AwardsImages,
  image_gallery: ImageGallery,
  link_cards: LinkCards,
  quotes: Quotes,
  seasons: Seasons,
  special_section: SpecialSection,
  text: Text,
  team_members: TeamMembers,
  video_loop: VideoLoop,
};
