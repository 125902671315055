import React from 'react';

import { FaFacebookF } from 'react-icons/fa/';
import { FaInstagram } from 'react-icons/fa/';
import { FaTwitter } from 'react-icons/fa/';
import { FaYoutube } from 'react-icons/fa/';

const SocialIcons = () => (
  <div className="social-icons">
    <div className="social-icon-container">
      <a
        href="https://www.facebook.com/GOMcircus"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="visually-hidden">Facebook</span>
        <FaFacebookF />
      </a>
    </div>
    <div className="social-icon-container">
      <a
        href="https://www.instagram.com/gomcircus/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="visually-hidden">Instagram</span>
        <FaInstagram />
      </a>
    </div>
    <div className="social-icon-container">
      <a
        href="https://twitter.com/GOM_Circus"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="visually-hidden">Twitter</span>
        <FaTwitter />
      </a>
    </div>
    <div className="social-icon-container">
      <a
        href="https://www.youtube.com/user/GOMcircus"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="visually-hidden">Youtube</span>
        <FaYoutube />
      </a>
    </div>
  </div>
);

export default SocialIcons;
