import React, { useState } from 'react';

type Props = {
  buttonText: string;
  content: JSX.Element;
  initiallyOpen: boolean;
};

const Accordion = (props: Props) => {
  const { buttonText, content, initiallyOpen } = props;
  const [isOpen, setIsOpen] = useState(initiallyOpen);

  const handleButtonClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className={`accordion ${isOpen ? 'open' : ''}`}>
      <button
        onClick={handleButtonClick}
        aria-expanded={isOpen}
        className="accordion__button"
      >
        {buttonText}
      </button>
      <div className="accordion__content" tabIndex={isOpen ? 0 : -1}>
        {content}
      </div>
    </div>
  );
};

Accordion.defaultProps = {
  initiallyOpen: false,
};

export default Accordion;
