import * as React from 'react';
import { graphql } from 'gatsby';
import { SliceZone } from '@prismicio/react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import PageBanner from '../components/PageBanner';

import { components } from '../slices';
import { IGatsbyImageData } from 'gatsby-plugin-image';

type PropTypes = {
  data: {
    prismicPage: {
      data: {
        document_display_name: {
          text: string;
        };
        banner_image: {
          alt: string;
          gatsbyImageData: IGatsbyImageData;
        };
        darken_banner_image: boolean;
        full_width_banner_image: boolean;
        body: any;
      };
    };
  };
};

const PageTemplate = ({ data }: PropTypes) => {
  if (!data) return null;

  const doc = data.prismicPage.data;
  const bannerText =
    doc.document_display_name.text === 'Home'
      ? ''
      : doc.document_display_name.text;

  return (
    <Layout className={doc.document_display_name.text.toLowerCase()}>
      <SEO title={doc.document_display_name.text} />
      <PageBanner
        text={bannerText}
        image={{
          image: doc.banner_image.gatsbyImageData,
          alt: doc.banner_image.alt || '',
        }}
        darkenImage={doc.darken_banner_image}
        fullWidth={doc.full_width_banner_image}
      />
      <div className="prismic-slice-container">
        <SliceZone slices={doc.body} components={components} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PageQuery($id: String) {
    prismicPage(id: { eq: $id }) {
      _previewable
      data {
        document_display_name {
          text
        }
        banner_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        darken_banner_image
        full_width_banner_image
        body {
          ... on PrismicSlice {
            slice_type
          }
          ...PageDataBodyAwardsImages
          ...PageDataBodyImageGallery
          ...PageDataBodyLinkCards
          ...PageDataBodyQuotes
          ...PageDataBodySeasons
          ...PageDataBodySpecialSection
          ...PageDataBodyText
          ...PageDataBodyTeamMembers
          ...PageDataBodyVideoLoop
        }
      }
    }
  }
`;

// @ts-ignore
export default withPrismicPreview(PageTemplate);
