import * as React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';

type PropTypes = {
  slice: {
    primary: {
      columns: string;
      content: {
        richText: any;
      };
    };
    slice_label: string;
    slice_type: string;
  };
};

export const Text = ({ slice }: PropTypes) => {
  const columns = slice.primary.columns;
  const isCentered = slice.slice_label === 'center-align';

  return (
    <section
      className={classnames('prismic-section-contained', {
        'prismic-text-2col': columns === '2 Columns',
        'prismic-text-centered': isCentered,
      })}
    >
      <PrismicRichText field={slice.primary.content.richText} />
    </section>
  );
};

export const query = graphql`
  fragment PageDataBodyText on PrismicPageDataBodyText {
    primary {
      columns
      content {
        richText
      }
    }
    slice_label
  }
`;
