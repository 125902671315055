import { TouringHistory } from '../types';

const bbTouringHistory: TouringHistory = [
  {
    year: 2017,
    performances: [
      {
        show: 'Backbone',
        startDate: 'Mar',
        location: 'Adelaide, Australia',
        venue: 'Adelaide Festival',
      },
      {
        show: 'Backbone',
        startDate: 'May',
        location: 'Marl, Germany',
        venue: 'Ruhrfestspiele - Theater Marl',
      },
      {
        show: 'Backbone',
        startDate: 'Jun',
        location: 'Trutnov, Czech Republic',
        venue: 'CirkUFF',
      },
      {
        show: 'Backbone',
        startDate: 'Aug',
        location: 'Groningen, Netherlands',
        venue: 'Noorderzon Festival',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Melbourne Australia',
        venue: 'Melbourne Festival',
      },
    ],
  },
  {
    year: 2018,
    performances: [
      {
        show: 'Backbone',
        startDate: 'Jan',
        location: 'Sydney, Australia',
        venue: 'Sydney Festival',
      },
      {
        show: 'Backbone',
        startDate: 'Feb',
        location: 'Brisbane, Australia',
        venue: 'Brisbane Powerhouse',
      },
      {
        show: 'Backbone',
        startDate: 'Apr',
        location: 'Whyalla, Australia',
        venue: 'Middleback Arts Centre',
      },
      {
        show: 'Backbone',
        startDate: 'May',
        location: 'Charleston, SC, USA',
        venue: 'Sploleto Festival',
      },
      {
        show: 'Backbone',
        startDate: 'Jun',
        location: 'Sibiu, Romania',
        venue: 'Sibiu International Theatre Festival',
      },
      {
        show: 'Backbone',
        startDate: 'Jun',
        location: 'Prague, Czech Republic',
        venue: 'Jatka78',
      },
      {
        show: 'Backbone',
        startDate: 'Jun',
        location: 'Luxembourg',
        venue: 'Wiltz Festival',
      },
      {
        show: 'Backbone',
        startDate: 'Jun',
        location: 'Kortrijk, Belgium',
        venue: 'PERPLX',
      },
      {
        show: 'Backbone',
        startDate: 'July',
        location: 'Montreal, Canada',
        venue: 'Montreal Completement Cirque',
      },
      {
        show: 'Backbone',
        startDate: 'July',
        location: 'Galway, Ireland',
        venue: 'Galway Arts Festival',
      },
      {
        show: 'Backbone',
        startDate: 'July',
        location: 'Stuttgart, Germany',
        venue: 'Theaterhaus Stuttgart',
      },
      {
        show: 'Backbone',
        startDate: 'Aug',
        location: 'Wolfsburg, Germany',
        venue: 'Autostadt Summer Festival',
      },
      {
        show: 'Backbone',
        startDate: 'Aug',
        location: 'London, UK',
        venue: 'Southbank Centre',
      },
      {
        show: 'Backbone',
        startDate: 'Sep',
        location: 'Budapest, Hungary',
        venue: 'Trafo',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Turnhout, Belgium',
        venue: 'De Warande',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Heist-op-den-Berg, Belgium',
        venue: 'Zwaneberg',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Roeselare, Belgium',
        venue: 'De Spil - Roeselare',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Aalst, Belgium',
        venue: 'Cultuurcentrum Aalst',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Beveren, Belgium',
        venue: 'Cultuur Centrum Beveren',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Amersfoort, Netherlands',
        venue: 'Rabobank Theater Hall',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Eindhoven, Netherlands',
        venue: 'Park Theatre',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Drachten, Netherlands',
        venue: 'Schouwburg De Lawei',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Utrecht, Netherlands',
        venue: 'Stadsschouwburg',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Montbéliard, France',
        venue: 'The Mals – Theater Sochaux',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Rotterdam, Netherlands',
        venue: 'Theatre Rotterdam',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Diest, Belgium',
        venue: 'CC Den Amer',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Genk, Belgium',
        venue: 'Cirq C-mine',
      },
      {
        show: 'Backbone',
        startDate: 'Nov',
        location: 'Vancouver, Canada',
        venue: 'Vancouver Playhouse Theatre',
      },
      {
        show: 'Backbone',
        startDate: 'Nov',
        location: 'Montreal, Canada',
        venue: 'CINARS Biennale',
      },
      {
        show: 'Backbone',
        startDate: 'Dec',
        location: 'Graz, Austria',
        venue: 'Cirque Noël',
      },
    ],
  },
  {
    year: 2019,
    performances: [
      {
        show: 'Backbone',
        startDate: 'Mar',
        location: 'Auckland, New Zealand',
        venue: 'Auckland Festival',
      },
      {
        show: 'Backbone',
        startDate: 'Mar',
        location: 'Karlskrona, Sweden',
        venue: 'Konserthusteatern',
      },
      {
        show: 'Backbone',
        startDate: 'Mar',
        location: 'Sundsvall, Sweden',
        venue: 'Tonhallen',
      },
      {
        show: 'Backbone',
        startDate: 'Mar',
        location: 'Piteå, Sweden',
        venue: 'Nolia City Konferens',
      },
      {
        show: 'Backbone',
        startDate: 'Apr',
        location: 'Umeå, Sweden',
        venue: 'Folkets Hus',
      },
      {
        show: 'Backbone',
        startDate: 'Apr',
        location: 'Östersund, Sweden',
        venue: 'Storsjöteatern',
      },
      {
        show: 'Backbone',
        startDate: 'Apr',
        location: 'Säffle, Sweden',
        venue: 'Medborgarhuset',
      },
      {
        show: 'Backbone',
        startDate: 'Apr',
        location: 'Skövde, Sweden',
        latLng: [58.3903, 13.8461],
        venue: 'Stadsteatern',
      },
      {
        show: 'Backbone',
        startDate: 'Apr',
        location: 'Vara, Sweden',
        latLng: [58.2618, 12.9602],
        venue: 'Vara Konserthus',
      },
      {
        show: 'Backbone',
        startDate: 'Apr',
        location: 'Göteborg, Sweden',
        latLng: [57.7089, 11.9746],
        venue: 'Stora Teatern',
      },
      {
        show: 'Backbone',
        startDate: 'Apr',
        location: 'Kungsbacka, Sweden',
        latLng: [57.4875, 12.0762],
        venue: 'Kungsbacka Teater',
      },
      {
        show: 'Backbone',
        startDate: 'Apr',
        location: 'Borås, Sweden',
        latLng: [57.721, 12.9398],
        venue: 'Borås Stadsteater',
      },
      {
        show: 'Backbone',
        startDate: 'May',
        location: 'Busan, South Korea',
        latLng: [35.1796, 129.0756],
        venue: 'Busan Cultural Center',
      },
      {
        show: 'Backbone',
        startDate: 'May',
        location: 'Anyang, South Korea',
        venue: 'Anyang Arts Centre',
      },
      {
        show: 'Backbone',
        startDate: 'May',
        location: 'Gwangmyeong, South Korea',
        latLng: [37.4785, 126.8643],
        venue: "Gwangmyeong Citizen's Hall",
      },
      {
        show: 'Backbone',
        startDate: 'May',
        location: 'Uijeongbu, South Korea',
        latLng: [37.7381, 127.0337],
        venue: 'Uijeongbu Music Theatre Festival',
      },
      {
        show: 'Backbone',
        startDate: 'May',
        location: 'Brighton UK',
        latLng: [50.8225, -0.1372],
        venue: 'Brighton Festival',
      },
      {
        show: 'Backbone',
        startDate: 'May',
        location: 'Helsinki, Finland',
        latLng: [60.1699, 24.9384],
        venue: 'Cirko Festival',
      },
      {
        show: 'Backbone',
        startDate: 'June',
        location: 'São Paulo, Brazil',
        latLng: [-23.5505, -46.6333],
        venue: 'Sesc Pinheiros',
      },
      {
        show: 'Backbone',
        startDate: 'July',
        location: 'Amsterdam, Netherlands',
        latLng: [52.368, 4.9036],
        venue: 'Royal Theater Carré',
      },
      {
        show: 'Backbone',
        startDate: 'July',
        location: 'Barcelona, Spain',
        latLng: [41.3851, 2.1734],
        venue: 'Grec Festival',
      },
      {
        show: 'Backbone',
        startDate: 'Aug',
        location: 'Edinburgh, Scotland',
        latLng: [55.9533, -3.1883],
        venue: 'Edinburgh Fringe Festival',
      },
      {
        show: 'Backbone',
        startDate: 'Sep',
        location: 'Delft, Netherlands',
        latLng: [52.0116, 4.3571],
        venue: 'Theater de Veste',
      },
      {
        show: 'Backbone',
        startDate: 'Sep',
        location: 'Haarlem, Netherlands',
        latLng: [52.3874, 4.6462],
        venue: 'Stadsschouwburg Zaal',
      },
      {
        show: 'Backbone',
        startDate: 'Sep',
        location: 'Karlsruhe, Germany',
        latLng: [49.0069, 8.4037],
        venue: 'Tollhaus',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Stanford, CA, USA',
        latLng: [37.4275, -122.1697],
        venue: 'Memorial Auditorium',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Irvine, CA, USA',
        latLng: [33.6846, -117.8265],
        venue: 'Irvine Barclay Theatre',
      },
      {
        show: 'Backbone',
        startDate: 'Oct',
        location: 'Santa Monica, CA, USA',
        latLng: [34.0195, -118.4912],
        venue: 'The Broad Stage',
      },
      {
        show: 'Backbone',
        startDate: 'Nov',
        location: 'Vernon, BC, Canada',
        latLng: [50.267, -119.272],
        venue: 'Vernon & District Performing Arts Centre',
      },
      {
        show: 'Backbone',
        startDate: 'Nov',
        location: 'Medicine Hat, BA, Canada',
        latLng: [50.0405, -110.6764],
        venue: 'Esplanade Arts and Heritage Centre',
      },
      {
        show: 'Backbone',
        startDate: 'Nov',
        location: 'Prince Albert, SK, Canada',
        latLng: [53.2033, -105.7531],
        venue: 'EA Rawlinson Centre',
      },
      {
        show: 'Backbone',
        startDate: 'Nov',
        location: 'Camrose, AB, Canada',
        latLng: [53.0222, -112.8286],
        venue: 'Camrose Performing Arts Centre',
      },
      {
        show: 'Backbone',
        startDate: 'Nov',
        location: 'Fort Saskatchewan, AB, Canada',
        latLng: [53.7007, -113.2095],
        venue: 'Dow Centennial Centre, Shell Theatre',
      },
      {
        show: 'Backbone',
        startDate: 'Nov',
        location: 'Fort McMurray, AB, Canada',
        latLng: [56.7264, -111.3803],
        venue: 'Keyano College Theatre & Arts Centre',
      },
      {
        show: 'Backbone',
        startDate: 'Nov',
        location: 'Châlons-en-Champagne, France',
        latLng: [48.9567, 4.3631],
        venue: 'La Comète Scène Nationale',
      },
      {
        show: 'Backbone',
        startDate: 'Dec',
        location: 'Paris, France',
        latLng: [48.8566, 2.3522],
        venue: 'La Villette',
      },
    ],
  },
  {
    year: 2020,
    performances: [
      {
        show: 'Backbone',
        startDate: 'Jan',
        location: 'Mâcon, France',
        latLng: [46.3069, 4.8287],
        venue: 'La Scène Nationale',
      },
      {
        show: 'Backbone',
        startDate: 'Jan',
        location: 'Cergy, France',
        latLng: [49.0356, 2.0603],
        venue: 'Nouvelle Scène Nationale',
      },
      {
        show: 'Backbone',
        startDate: 'Jan',
        location: 'Caluire et Cuire, France',
        latLng: [45.7968, 4.8424],
        venue: 'Le Radiant Bellevue',
      },
      {
        show: 'Backbone',
        startDate: 'Jan',
        location: 'Fréjus, France',
        latLng: [43.4332, 6.737],
        venue: 'Théâtre le Forum',
      },
      {
        show: 'Backbone',
        startDate: 'Jan',
        location: 'Fos sur Mer, France',
        latLng: [43.4379, 4.9457],
        venue: 'Théâtre',
      },
      {
        show: 'Backbone',
        startDate: 'Jan',
        location: 'Lillebonne, France',
        latLng: [49.5189, 0.5345],
        venue: 'Centre Culturel Juliobona',
      },
      {
        show: 'Backbone',
        startDate: 'Jan',
        location: 'Morges, Switzerland',
        latLng: [46.5088, 6.4961],
        venue: 'Théâtre Beausobre',
      },
      {
        show: 'Backbone',
        startDate: 'Feb',
        location: 'St.Therese, QC, Canada',
        venue: 'Théâtre Lionel-Groulx',
        latLng: [45.6397, -73.8439],
      },
      {
        show: 'Backbone',
        startDate: 'Feb',
        location: 'Saint John, NB, Canada',
        venue: 'Imperial Theatre',
        latLng: [47.5615, -52.7126],
      },
      {
        show: 'Backbone',
        startDate: 'Feb',
        location: 'Fredericton, NB, Canada',
        venue: 'The Playhouse',
        latLng: [45.9636, -45.9636],
      },
    ],
  },
  {
    year: 2021,
    performances: [
      {
        show: 'Backbone',
        startDate: 'Nov',
        location: 'St.Pölten, Austria',
        venue: 'Festspielhaus',
        latLng: [48.13, 15.37],
      },
      {
        show: 'Backbone',
        startDate: 'Dec',
        location: 'Ballerup, Denmark',
        venue: 'BALTOPPEN Live',
        latLng: [55.7245, 12.3548],
      },
      {
        show: 'Backbone',
        startDate: 'Dec',
        location: 'Salzburg, Austria',
        venue: 'Winterfest',
        latLng: [47.797133, 13.059723],
      },
    ],
  },
  {
    year: 2022,
    performances: [
      {
        show: 'Backbone',
        startDate: 'Jan',
        location: 'Cébazat, France',
        venue: 'Semaphore Cebazat',
        latLng: [45.8313, 3.1002],
      },
      {
        show: 'Backbone',
        startDate: 'Jan',
        location: 'Cusset, France',
        venue: 'Théâtre de Cusset',
        latLng: [46.132, 3.4565],
      },
      {
        show: 'Backbone',
        startDate: 'Jan',
        location: 'Clichy sous Bois, France',
        venue: "L'espace 93",
        latLng: [48.9109, 2.5461],
      },
      {
        show: 'Backbone',
        startDate: 'Jan',
        location: 'Plessis Robinson, France',
        venue: 'Theéâtre DE L’Allegria',
        latLng: [48.7804, 2.2663],
      },
      {
        show: 'Backbone',
        startDate: 'Jan',
        location: 'Noisy le Grand, France',
        venue: '',
        latLng: [48.8486, 2.5526],
      },
      {
        show: 'Backbone',
        startDate: 'Jan',
        location: 'Rungis, France',
        venue: 'Theatre De Rungis',
        latLng: [48.7494, 2.3499],
      },
      {
        show: 'Backbone',
        startDate: 'Jan',
        location: 'Le Havre, France',
        venue: 'Le Volcan - Grande salle',
        latLng: [49.4944, 0.1079],
      },
      {
        show: 'Backbone',
        startDate: 'Feb',
        location: 'Mérignac, France',
        venue: 'Le Pin Galant',
        latLng: [44.8449, -0.6564],
      },
      {
        show: 'Backbone',
        startDate: 'Feb',
        location: 'Biarritz, France',
        venue: 'Gare Du Midi',
        latLng: [43.4832, -1.5586],
      },
      {
        show: 'Backbone',
        startDate: 'Feb',
        location: 'Mont de Marsan, France',
        venue: 'Le Pôle',
        latLng: [43.8935, -0.4998],
      },
      {
        show: 'Backbone',
        startDate: 'Feb',
        location: 'Blagnac, France',
        venue: 'Odyssud Blagnac',
        latLng: [43.6351, 1.397],
      },
      {
        show: 'Backbone',
        startDate: 'Feb',
        location: 'Nîmes, France',
        venue: 'Théâtre de Nîmes',
        latLng: [43.8367, 4.3601],
      },
      {
        show: 'Backbone',
        startDate: 'Feb',
        location: 'Saint-Denis, Réunion',
        venue: 'TEAT ile de La Réunion',
        latLng: [-20.888623, 55.466096],
      },

      {
        show: 'Backbone',
        startDate: 'March',
        location: 'Arendal, Norway',
        venue: 'Arendal Kulturhus',
        latLng: [58.4618, 8.7724],
      },
      {
        show: 'Backbone',
        startDate: 'March',
        location: 'Nøtterøy, Norway',
        venue: 'Nøtterøy Kulturhus',
        latLng: [59.224646, 10.409922],
      },
      {
        show: 'Backbone',
        startDate: 'March',
        location: 'Skien, Norway',
        venue: 'Ibsenhuset',
        latLng: [59.207442, 9.60568],
      },
      {
        show: 'Backbone',
        startDate: 'March',
        location: 'Bærum, Norway',
        venue: 'Bærum Kulturhus',
        latLng: [59.888684, 10.522556],
      },
      {
        show: 'Backbone',
        startDate: 'March',
        location: 'Drammen, Norway',
        venue: 'Drammen',
        latLng: [59.742712, 10.205233],
      },
      {
        show: 'Backbone',
        startDate: 'March',
        location: 'Aix-en-Provence, France',
        venue: 'Grand Théâtre de Provence',
        latLng: [43.525613, 5.44048],
      },
      {
        show: 'Backbone',
        startDate: 'March',
        location: 'Lyon, France',
        venue: 'Maison de la Danse',
        latLng: [45.73505, 4.872372],
      },
      {
        show: 'Backbone',
        startDate: 'April',
        endDate: '',
        location: 'Saint Maxime, France',
        venue: 'Carré Léon Gaumont',
        latLng: [43.324782, 6.624738],
      },
      {
        show: 'Backbone',
        startDate: 'April',
        location: 'Haugesund, Norway',
        venue: 'Haugesund Konserthus',
        latLng: [59.410161, 5.274272],
      },
      {
        show: 'Backbone',
        startDate: 'April',
        location: 'Stavanger, Norway',
        venue: 'Stavanger Konserthus',
        latLng: [58.975377, 5.723758],
      },
      {
        show: 'Backbone',
        startDate: 'April',
        location: 'Larvik, Norway',
        venue: 'Larvik',
        latLng: [59.054132, 10.029051],
      },
      {
        show: 'Backbone',
        startDate: 'April',
        location: 'Moss, Norway',
        venue: 'Moss Kulturhus',
        latLng: [59.435344, 10.660379],
      },
    ],
  },
];

export default bbTouringHistory;
