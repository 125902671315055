import React from 'react';
import classnames from 'classnames';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

type PageBannerProps = {
  image: {
    alt: string;
    image: IGatsbyImageData;
  };
  darkenImage?: boolean;
  fullWidth?: boolean;
  text?: React.ReactNode | null;
};

const PageBanner = (props: PageBannerProps) => {
  const { image, darkenImage = false, fullWidth = false, text } = props;
  return (
    <div
      className={classnames('page-banner', {
        dark: darkenImage,
        'full-width': fullWidth,
      })}
    >
      <div className="page-banner__inner">
        {image && (
          <GatsbyImage
            image={image.image}
            alt={image.alt}
            imgStyle={{ objectFit: 'contain' }}
          />
        )}

        {text && (
          <div className="page-banner__content-container">
            <h1 className="page-banner__title">{text}</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default PageBanner;
