import { PrismicSeason } from '../types';

// Pulled 26 Feb

/*
query MyQuery {
  allPrismicSeason {
    nodes {
      data {
        end_date
        geo {
          latitude
          longitude
        }
        location {
          text
        }
        show
        start_date
        ticket_link {
          url
        }
        venue {
          text
        }
      }
      id
    }
  }
}
*/

export const touringHistory: PrismicSeason[] = [
  {
    data: {
      end_date: '2024-04-12',
      geo: {
        latitude: 44.8751993,
        longitude: 4.8808533,
      },
      location: {
        text: 'Valence,  France',
      },
      show: 'a-simple-space',
      start_date: '2024-04-10',
      ticket_link: {
        url: 'https://www.train-theatre.fr/evenement/a-simple-space/',
      },
      venue: {
        text: 'Le Train-Théâtre',
      },
    },
    id: 'cb491cb0-66ba-5d6b-b5ec-80ccbaf3a665',
  },
  {
    data: {
      end_date: '2024-03-23',
      geo: {
        latitude: 48.0578794,
        longitude: -0.8515826,
      },
      location: {
        text: 'Laval, France',
      },
      show: 'backbone',
      start_date: '2024-03-22',
      ticket_link: {
        url: 'https://letheatre.laval.fr/spectacles/backbone/',
      },
      venue: {
        text: 'Le Théâtre de Laval',
      },
    },
    id: 'b487a6bb-3fbe-5c34-ae82-063bf8b39729',
  },
  {
    data: {
      end_date: '2024-02-25',
      geo: {
        latitude: -34.9229196,
        longitude: 138.6121318,
      },
      location: {
        text: 'Adelaide, Australia',
      },
      show: 'ten-thousand-hours',
      start_date: '2024-02-16',
      ticket_link: {
        url: 'https://adelaidefringe.com.au/fringetix/ten-thousand-hours-af2024',
      },
      venue: {
        text: 'The Peacock at Gluttony, Adelaide Fringe',
      },
    },
    id: '1f7cecb8-8727-5c2a-bc76-a147d110b440',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 46.2599966,
        longitude: 5.5693471,
      },
      location: {
        text: 'Oyonnax, France',
      },
      show: 'backbone',
      start_date: '2024-03-12',
      ticket_link: {
        url: 'https://www.ain-tourisme.com/fiches/Oyonnax/Culture/Cirque-contemporain-par-les-acrobates-Gravity-Other-Myths/5918380/',
      },
      venue: {
        text: "L'Ain, Aragon Cultural Center",
      },
    },
    id: '3956e0c1-5cb2-5b5e-8a23-f1610f8e48b4',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 47.7686186,
        longitude: -3.3672023,
      },
      location: {
        text: 'Lanester, France',
      },
      show: 'backbone',
      start_date: '2024-03-19',
      ticket_link: {
        url: 'https://www.lanester.bzh/actualites/agenda/evenement/?tx_news_pi1%5Bnews%5D=50460&tx_news_pi1%5Bcontroller%5D=News&tx_news_pi1%5Baction%5D=detail&cHash=14cc3fb803ea8ba8af2e88ef72ce15ca',
      },
      venue: {
        text: 'MAIRIE DE LANESTER',
      },
    },
    id: '59cdd56a-a5c7-55b2-a40b-3b5fbc098b7c',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 48.8802173,
        longitude: 2.1813123,
      },
      location: {
        text: 'Rueil Malmaison, France',
      },
      show: 'a-simple-space',
      start_date: '2024-04-04',
      ticket_link: {
        url: 'https://www.tam.fr/spectacle/a-simple-space/',
      },
      venue: {
        text: 'Théâtre André-Malraux',
      },
    },
    id: 'a8f44178-e823-5d94-830b-4d4b1ea4c81a',
  },
  {
    data: {
      end_date: '2024-03-16',
      geo: {
        latitude: 46.1620512,
        longitude: -1.1764737,
      },
      location: {
        text: 'La Rochelle, France',
      },
      show: 'backbone',
      start_date: '2024-03-14',
      ticket_link: {
        url: 'https://www.la-coursive.com/projects/backbone-23-24/',
      },
      venue: {
        text: 'La Coursive',
      },
    },
    id: 'd9fb20ca-ba54-594c-a549-4430ac323e09',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 48.8968,
        longitude: 2.097,
      },
      location: {
        text: 'Saint Germain en Laye',
      },
      show: 'a-simple-space',
      start_date: '2024-04-23',
      ticket_link: {
        url: 'https://tad.saintgermainenlaye.fr/1629-5168/fiche/a-simple-space.htm',
      },
      venue: {
        text: 'Théâtre Alexandre Dumas',
      },
    },
    id: '296d172b-6c0c-52c6-a300-53c1eb1d1612',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 48.7914155,
        longitude: 2.311226,
      },
      location: {
        text: 'Cachan, France',
      },
      show: 'backbone',
      start_date: '2024-03-26',
      ticket_link: {
        url: 'https://www.theatreonline.com/Spectacle/Backbone/83241',
      },
      venue: {
        text: 'Théâtre Jacques Carat',
      },
    },
    id: 'd3d911b1-42f1-5cdc-8ca0-bcaff92926be',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 48.9108725,
        longitude: 2.5450969,
      },
      location: {
        text: 'Clichy sous Bois, France',
      },
      show: 'a-simple-space',
      start_date: '2024-04-05',
      ticket_link: {
        url: 'https://www.lespace93.fr/evenement/a-simple-space-cie-gravity-other-myths/',
      },
      venue: {
        text: 'ESPACE 93 ',
      },
    },
    id: '96bd6d4d-118e-5e63-80d9-bd414a854b58',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 48.4766472,
        longitude: -2.5003829,
      },
      location: {
        text: 'Lamballe, France',
      },
      show: 'a-simple-space',
      start_date: '2024-04-17',
      ticket_link: {
        url: 'https://www.cotesdarmor.com/fr/fiche/fetes-et-manifestations/cirque-a-simple-space-lamballe-armor_TFOFMABRE022V545ZNR/',
      },
      venue: {
        text: 'Quai des Rêves',
      },
    },
    id: '4f6ec609-df64-5d38-be59-30907e86fb95',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 43.7923486,
        longitude: 4.8418667,
      },
      location: {
        text: 'Saint Remy, France',
      },
      show: 'a-simple-space',
      start_date: '2024-04-13',
      ticket_link: {
        url: 'https://www.alpillesenprovence.com/en/fiches/alpilium-a-simple-space/',
      },
      venue: {
        text: 'Alpilium',
      },
    },
    id: '2c1aef64-27dd-58e6-b52e-ace71a463815',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 45.9092715,
        longitude: 6.0880854,
      },
      location: {
        text: 'Annecy, France',
      },
      show: 'a-simple-space',
      start_date: '2024-04-09',
      ticket_link: {
        url: 'https://theatredescollines.annecy.fr/prog/a-simple-space/',
      },
      venue: {
        text: 'Théâtre des collines',
      },
    },
    id: '8dc4f00f-5528-51e7-8b97-7858fe59eb71',
  },
  {
    data: {
      end_date: '2024-03-21',
      geo: {
        latitude: 46.6660664,
        longitude: -1.5810068,
      },
      location: {
        text: 'La Roche sur Yon, France',
      },
      show: 'backbone',
      start_date: '2024-03-20',
      ticket_link: {
        url: 'https://www.legrandr.com/saisons/23-24/backbone/',
      },
      venue: {
        text: 'Le Grand R',
      },
    },
    id: '7bcc466c-9c66-59cd-87b7-6cfdb6f2b31d',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 46.5162034,
        longitude: 6.4969865,
      },
      location: {
        text: 'Morges, Switzerland',
      },
      show: 'a-simple-space',
      start_date: '2024-04-28',
      ticket_link: {
        url: 'https://beausobre.ch/spectacles/a-simple-space/',
      },
      venue: {
        text: 'Théâtre de Beausobre',
      },
    },
    id: '407e5104-778a-5bc9-b958-0281db44e616',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 47.2995982,
        longitude: -1.5032042,
      },
      location: {
        text: 'Carquefou, France',
      },
      show: 'a-simple-space',
      start_date: '2024-04-16',
      ticket_link: {
        url: 'https://www.theatre-carquefou.fr/saison-23-24/spectacle/a-simple-space/',
      },
      venue: {
        text: 'Théâtre de la Fleuriaye',
      },
    },
    id: 'cdf9e5d3-e496-53c4-8beb-0fa0944d1cce',
  },
  {
    data: {
      end_date: '2024-04-27',
      geo: {
        latitude: 46.8034565,
        longitude: 7.1509978,
      },
      location: {
        text: 'Fribourg, Switzerland',
      },
      show: 'a-simple-space',
      start_date: '2024-04-26',
      ticket_link: {
        url: 'https://fribourg.ch/fr/fribourg/evenements/a-simple-space/',
      },
      venue: {
        text: 'Equilibre',
      },
    },
    id: 'b7f973e1-745e-51ab-9ceb-ad078cb229a9',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 48.6539117,
        longitude: 1.7514721,
      },
      location: {
        text: 'Rambouillet, France',
      },
      show: 'backbone',
      start_date: '2024-03-02',
      ticket_link: {
        url: 'https://www.rambouillet-tourisme.fr/en/backbone/',
      },
      venue: {
        text: 'La Lanterne',
      },
    },
    id: 'b0180538-c481-5526-985a-b3f6d39bf647',
  },
  {
    data: {
      end_date: '2024-04-20',
      geo: {
        latitude: 43.4276347,
        longitude: 6.7350371,
      },
      location: {
        text: 'Fréjus, France',
      },
      show: 'a-simple-space',
      start_date: '2024-04-19',
      ticket_link: {
        url: 'https://www.theatreleforum.fr/spectacle/a-simple-space-gravity-other-myths/',
      },
      venue: {
        text: 'Théâtre LE FORUM',
      },
    },
    id: '6e482aa9-f637-539b-853c-ce9f6e84a1d5',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 48.4346651,
        longitude: 2.9671826,
      },
      location: {
        text: 'Troyes, France',
      },
      show: 'a-simple-space',
      start_date: '2024-04-02',
      ticket_link: {
        url: 'https://www.lart-deco.com/events/a-simple-space',
      },
      venue: {
        text: "L'Art Déco",
      },
    },
    id: 'cf776969-2f64-55bc-b959-fb0ecd4a8163',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 48.8467613,
        longitude: 2.5070627,
      },
      location: {
        text: 'Le Perreux sur Marne, France',
      },
      show: 'a-simple-space',
      start_date: '2024-04-24',
      ticket_link: {
        url: 'https://www.cdbm.org/spectacle/a-simple-space/',
      },
      venue: {
        text: 'Centre des bords de Marne',
      },
    },
    id: 'bf54d614-3ced-599b-be4a-66edbcf3899a',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 45.6764557,
        longitude: 6.3887161,
      },
      location: {
        text: 'Albertville, France',
      },
      show: 'a-simple-space',
      start_date: '2024-04-08',
      ticket_link: {
        url: 'https://www.dometheatre.com/a-simple-space.html',
      },
      venue: {
        text: 'Le Dôme Théâtre',
      },
    },
    id: '640e5ac5-cb09-5078-ad46-baba0bf2836b',
  },
  {
    data: {
      end_date: '2024-03-03',
      geo: {
        latitude: 45.5583940632062,
        longitude: -73.61599473382468,
      },
      location: {
        text: 'Montreal, Canada',
      },
      show: 'the-mirror',
      start_date: '2024-02-21',
      ticket_link: {
        url: 'https://tohu.ca/en/season/the-mirror',
      },
      venue: {
        text: 'TOHU',
      },
    },
    id: 'f626a408-c52b-5b9d-b687-05ec6006d493',
  },
  {
    data: {
      end_date: '2023-10-22',
      geo: {
        latitude: 31.2442446,
        longitude: 121.5120051,
      },
      location: {
        text: 'Shanghai, China',
      },
      show: 'a-simple-space',
      start_date: '2023-10-04',
      ticket_link: {
        url: 'https://www.smartticket.cn/tickets/a_simple_space',
      },
      venue: {
        text: '1862 Theatre',
      },
    },
    id: '301debaf-9a3f-556f-b50f-be137ba49bf7',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 47.0739977,
        longitude: 5.4199733,
      },
      location: {
        text: 'Dole, France',
      },
      show: 'backbone',
      start_date: '2024-03-09',
      ticket_link: {
        url: 'https://www.scenesdujura.com/backbone-cirque-gravity-other-myths,spectacle-354.htm',
      },
      venue: {
        text: 'Les Scènes du Jura',
      },
    },
    id: '31133231-b2b7-57c6-a719-7d0096fef38d',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 48.9241395,
        longitude: 1.9426476,
      },
      location: {
        text: 'Poissy, France',
      },
      show: 'backbone',
      start_date: '2024-03-01',
      ticket_link: {
        url: 'https://www.ville-poissy.fr/index.php/agenda/1547-backbone.html',
      },
      venue: {
        text: 'Théâtre de Poissy',
      },
    },
    id: '5e8414ca-62d5-5f7e-ae67-2cb7a9e1a7f8',
  },
  {
    data: {
      end_date: '2023-08-12',
      geo: {
        latitude: -12.4617395,
        longitude: 130.8451901,
      },
      location: {
        text: 'Darwin, Australia',
      },
      show: 'a-simple-space',
      start_date: '2023-08-10',
      ticket_link: {
        url: 'https://www.darwinfestival.org.au/events/a-simple-space/',
      },
      venue: {
        text: 'Darwin Festival',
      },
    },
    id: 'a8ceeedd-c3e0-58aa-a8ae-d30eb32ab892',
  },
  {
    data: {
      end_date: '2024-05-05',
      geo: {
        latitude: 51.62120763054757,
        longitude: 7.18579246978882,
      },
      location: {
        text: 'Recklinghausen, Germany',
      },
      show: 'the-pulse',
      start_date: '2024-05-03',
      ticket_link: {
        url: 'https://www.ruhrfestspiele.de/programm/2024/the-pulse',
      },
      venue: {
        text: 'Ruhrfestspielhaus Großes Haus',
      },
    },
    id: '20650a08-a2a4-53b6-b583-30ea7573977c',
  },
  {
    data: {
      end_date: '2024-02-10',
      geo: {
        latitude: 46.81274445913032,
        longitude: -71.21537826012383,
      },
      location: {
        text: 'Québec, Canada',
      },
      show: 'the-mirror',
      start_date: '2024-02-08',
      ticket_link: {
        url: 'https://www.lediamant.ca/fr/programmation/the-mirror',
      },
      venue: {
        text: 'Le Diamant',
      },
    },
    id: 'd474d4e2-af93-559a-b7eb-3309261ed25a',
  },
  {
    data: {
      end_date: '2023-11-19',
      geo: {
        latitude: 30.5807917,
        longitude: 114.270144,
      },
      location: {
        text: 'Wuhan, China',
      },
      show: 'a-simple-space',
      start_date: '2023-11-03',
      ticket_link: {
        url: null,
      },
      venue: {
        text: 'Wuhan Shopping Mall',
      },
    },
    id: 'a0a0a2a2-0072-579b-afa4-6516f8376c99',
  },
  {
    data: {
      end_date: '2024-04-28',
      geo: {
        latitude: 50.09991724728054,
        longitude: 14.446274356222215,
      },
      location: {
        text: 'Prague, Czech Republic',
      },
      show: 'the-mirror',
      start_date: '2024-04-25',
      ticket_link: {
        url: 'https://www.jatka78.cz/en/play/the-mirror',
      },
      venue: {
        text: 'Jatka78',
      },
    },
    id: '63c7c8a9-8a07-588a-9de3-18f61522a42d',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 44.8164121,
        longitude: 20.4519429,
      },
      location: {
        text: 'Belgrade, Serbia',
      },
      show: 'a-simple-space',
      start_date: '2024-03-29',
      ticket_link: {
        url: 'https://belgradedancefestival.com/predstava/gravity',
      },
      venue: {
        text: 'Balkan Cinema',
      },
    },
    id: '73fd7b3f-d731-5877-96f1-ac7f63a1f6ce',
  },
  {
    data: {
      end_date: '2023-12-09',
      geo: {
        latitude: 33.8831367,
        longitude: 82.8539093,
      },
      location: {
        text: 'China',
      },
      show: 'a-simple-space',
      start_date: '2023-10-03',
      ticket_link: {
        url: null,
      },
      venue: {
        text: 'Venues TBD',
      },
    },
    id: 'd6928e74-e1c4-56bf-8e7a-b1180c72adcc',
  },
  {
    data: {
      end_date: '2023-12-09',
      geo: {
        latitude: 36.9197455,
        longitude: 117.4263617,
      },
      location: {
        text: 'Harbin, China',
      },
      show: 'a-simple-space',
      start_date: '2023-12-06',
      ticket_link: {
        url: null,
      },
      venue: {
        text: '',
      },
    },
    id: 'db374037-faff-5aea-b651-52bdb6eb9618',
  },
  {
    data: {
      end_date: '2024-02-18',
      geo: {
        latitude: 43.646095237657775,
        longitude: -79.37638993278141,
      },
      location: {
        text: 'Toronto, Canada',
      },
      show: 'the-mirror',
      start_date: '2024-02-15',
      ticket_link: {
        url: 'https://www.tolive.com/Event-Details-Page/reference/The-Mirror-2024',
      },
      venue: {
        text: 'TOLive',
      },
    },
    id: 'fe1d08de-b3b6-5e06-b50b-8e1b787ada63',
  },
  {
    data: {
      end_date: '2023-12-03',
      geo: {
        latitude: 28.1942327,
        longitude: 112.8684821,
      },
      location: {
        text: 'Chang Shai Shi, China ',
      },
      show: 'a-simple-space',
      start_date: '2023-11-24',
      ticket_link: {
        url: null,
      },
      venue: {
        text: 'Meixihu Grand Theatre',
      },
    },
    id: '29a65774-5bb4-5977-9acc-bea3d467212a',
  },
  {
    data: {
      end_date: '2024-03-06',
      geo: {
        latitude: 44.1250456,
        longitude: 4.0492663,
      },
      location: {
        text: 'Alès, France',
      },
      show: 'backbone',
      start_date: '2024-03-05',
      ticket_link: {
        url: 'https://lecratere.fr/spectacle/backbone/',
      },
      venue: {
        text: 'Le Cratère | Scène nationale d’Ales',
      },
    },
    id: '8f6ab1bc-a869-5da2-9a8e-0851333a98de',
  },
  {
    data: {
      end_date: '2024-01-27',
      geo: {
        latitude: 49.27892323507897,
        longitude: -123.06896896924002,
      },
      location: {
        text: 'Vancouver, Canada',
      },
      show: 'the-mirror',
      start_date: '2024-01-25',
      ticket_link: {
        url: 'https://thecultch.com/event/the-mirror/',
      },
      venue: {
        text: 'The Cultch',
      },
    },
    id: 'a88b6816-142c-5e3a-8643-34035598a9df',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 63.1795982,
        longitude: 14.6330312,
      },
      location: {
        text: 'Östersund, Sweden',
      },
      show: 'out-of-chaos',
      start_date: '2023-04-17',
      ticket_link: {
        url: 'https://www.riksteatern.se/forening/scenkonst-ostersund--en-del-av-riksteatern/out-of-chaos/',
      },
      venue: {
        text: 'Storsjöteatern',
      },
    },
    id: '876f647d-2a4f-5184-b5cb-341281f2ed48',
  },
  {
    data: {
      end_date: '2023-10-29',
      geo: {
        latitude: 30.4210261,
        longitude: 120.2895395,
      },
      location: {
        text: 'Hangzhou, China',
      },
      show: 'a-simple-space',
      start_date: '2023-10-26',
      ticket_link: {
        url: null,
      },
      venue: {
        text: 'Lingping Grand Theater',
      },
    },
    id: '1c103dca-215c-5d08-b5ac-73778a70c346',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 46.8065324,
        longitude: 4.3845167,
      },
      location: {
        text: 'Le Creusot, France',
      },
      show: 'backbone',
      start_date: '2024-03-08',
      ticket_link: {
        url: 'https://www.larcscenenationale.fr/fr/lasaison/backbone',
      },
      venue: {
        text: "L'arc scène national Le Creusot",
      },
    },
    id: '9f83c62c-0fdf-5a90-b6ad-472216c88bef',
  },
  {
    data: {
      end_date: '2023-10-01',
      geo: {
        latitude: 33.5613111,
        longitude: 133.5731688,
      },
      location: {
        text: 'Kochi, Japan',
      },
      show: 'backbone',
      start_date: '2023-09-30',
      ticket_link: {
        url: 'https://moak.jp/event/performing_arts/gravityandothermyths-backbone-japan.html',
      },
      venue: {
        text: 'The Museum of Art',
      },
    },
    id: 'd7ad61d9-60bc-50de-b6cd-e714e5dcf24a',
  },
  {
    data: {
      end_date: '2023-01-08',
      geo: {
        latitude: 47.07359055282648,
        longitude: 15.44178895894006,
      },
      location: {
        text: 'Graz, Austria',
      },
      show: 'out-of-chaos',
      start_date: '2023-01-03',
      ticket_link: {
        url: 'https://www.cirque-noel.at/2022/gravity-other-myths-3/',
      },
      venue: {
        text: 'Cirque Noel',
      },
    },
    id: '09955bf9-5ee7-5066-ae17-7118d8165223',
  },
  {
    data: {
      end_date: '2023-01-25',
      geo: {
        latitude: 50.3643021,
        longitude: 3.0837611,
      },
      location: {
        text: 'Douai, France',
      },
      show: 'backbone',
      start_date: '2023-01-24',
      ticket_link: {
        url: 'https://www.tandem-arrasdouai.eu/evenement/backbone-gravity-other-mythshttps://',
      },
      venue: {
        text: 'TANDEM Scène nationale / Hippodrome de Douai',
      },
    },
    id: '3dcfe173-e881-59ec-bf72-47e7f1c33fbf',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 53.1030464,
        longitude: 6.0949039,
      },
      location: {
        text: 'Drachten, Netherlands',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-24',
      ticket_link: {
        url: 'https://www.lawei.nl/agenda/19874/Out_of_Chaos/Gravity_Other_Mythshttps://',
      },
      venue: {
        text: 'Schouwburg De Lawei',
      },
    },
    id: 'cc82a542-7f73-5084-b78c-77b88773cae8',
  },
  {
    data: {
      end_date: '2023-01-01',
      geo: {
        latitude: 46.1885256,
        longitude: 6.2363815,
      },
      location: {
        text: 'Annemasse, Auvergne-Rhône-Alpes, France',
      },
      show: 'backbone',
      start_date: '2023-01-31',
      ticket_link: {
        url: 'https://www.chateau-rouge.net/spectacle/backbone/https://',
      },
      venue: {
        text: 'Chateau Rouge',
      },
    },
    id: '0ca36b35-f9e7-59da-b8a3-a9ff650e6eb6',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 47.468811,
        longitude: -0.4982375,
      },
      location: {
        text: "Saint-Barthélemy-d'Anjou, France",
      },
      show: 'a-simple-space',
      start_date: '2022-11-30',
      ticket_link: {
        url: 'http://www.thv.fr/Agenda/A-Simple-Space-Cie-Gravity-Other-Myths',
      },
      venue: {
        text: "Théâtre de l'hôtel de ville",
      },
    },
    id: '86278b7e-edb5-5f79-8260-6937d59be9ec',
  },
  {
    data: {
      end_date: '2023-01-11',
      geo: {
        latitude: 48.9656101,
        longitude: 4.345048,
      },
      location: {
        text: 'Chalons, France',
      },
      show: 'out-of-chaos',
      start_date: '2023-01-10',
      ticket_link: {
        url: 'https://www.la-comete.fr/programmation/out-of-chaos',
      },
      venue: {
        text: 'La Comete',
      },
    },
    id: '44ed7de5-14b7-5bbe-8bee-d92b44d01608',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 51.42903607040991,
        longitude: 5.484645366668701,
      },
      location: {
        text: 'Eindhoven, Netherlands',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-08',
      ticket_link: {
        url: 'https://www.parktheater.nl/nl/programma/10766/Gravity_and_Other_Myths/Out_of_Chaoshttps://',
      },
      venue: {
        text: 'Parktheater',
      },
    },
    id: '14b24e47-7506-54b9-a297-b0a8676d4309',
  },
  {
    data: {
      end_date: '2023-04-27',
      geo: {
        latitude: 39.031700552649056,
        longitude: -77.10297234778338,
      },
      location: {
        text: 'North Bethesda, MD',
      },
      show: 'a-simple-space',
      start_date: '2023-04-26',
      ticket_link: {
        url: 'https://www.strathmore.org/events-tickets/in-the-music-center/gravity-and-other-myths/',
      },
      venue: {
        text: 'The Music Center at Strathmore',
      },
    },
    id: 'ee586317-ceee-5149-a3cd-ba5134cd8ee7',
  },
  {
    data: {
      end_date: '2023-05-14',
      geo: {
        latitude: 45.4163034,
        longitude: -75.7106147,
      },
      location: {
        text: 'Ottawa, ON',
      },
      show: 'a-simple-space',
      start_date: '2023-05-11',
      ticket_link: {
        url: 'https://ottawachildrensfestival.ca/',
      },
      venue: {
        text: 'Ottawa Childrens Festival',
      },
    },
    id: 'c1269b95-e58d-5062-8e57-ce6c683b4979',
  },
  {
    data: {
      end_date: '2023-07-22',
      geo: {
        latitude: 53.2791548,
        longitude: -9.0666081,
      },
      location: {
        text: 'Galway, Ireland',
      },
      show: 'the-pulse',
      start_date: '2023-07-17',
      ticket_link: {
        url: 'https://www.giaf.ie/festival/event/the-pulse',
      },
      venue: {
        text: 'Festival Theatre, Kingfisher, University of Galway',
      },
    },
    id: 'c81647a3-8b65-5b07-bed9-f52dbd6893d1',
  },
  {
    data: {
      end_date: '2023-02-28',
      geo: {
        latitude: 22.9775942,
        longitude: 120.2015339,
      },
      location: {
        text: 'Tianan, Taiwan',
      },
      show: 'a-simple-space',
      start_date: '2023-02-25',
      ticket_link: {
        url: 'https://tixfun.com/UTK0201_?PRODUCT_ID=Q00STTAD',
      },
      venue: {
        text: 'FOCASA Circus Festival',
      },
    },
    id: '901fd37c-507c-5e44-a443-5b9970898181',
  },
  {
    data: {
      end_date: '2023-04-29',
      geo: {
        latitude: 40.50090868386198,
        longitude: -78.01697807603624,
      },
      location: {
        text: 'Huntingdon, PA',
      },
      show: 'a-simple-space',
      start_date: '2023-04-28',
      ticket_link: {
        url: 'https://www.juniatapresents.com/tickets/simplespace',
      },
      venue: {
        text: 'Halbritter Center for the Performing Arts',
      },
    },
    id: '5a5a0998-e35d-5bbd-a351-6df565be5068',
  },
  {
    data: {
      end_date: '2022-12-10',
      geo: {
        latitude: 48.9736041,
        longitude: 2.0938164,
      },
      location: {
        text: 'Argenteuil, France',
      },
      show: 'a-simple-space',
      start_date: '2022-12-09',
      ticket_link: {
        url: 'https://lefiguierblanc.argenteuil.fr/fr/agenda/simple-space',
      },
      venue: {
        text: 'Le Figuier Blanc',
      },
    },
    id: 'ef1d4259-3681-5ebc-be88-3ae2aa6623a7',
  },
  {
    data: {
      end_date: '2023-03-11',
      geo: {
        latitude: -33.8567844,
        longitude: 151.2152967,
      },
      location: {
        text: 'Sydney, Australia',
      },
      show: 'the-mirror',
      start_date: '2023-02-09',
      ticket_link: {
        url: 'https://www.sydneyoperahouse.com/events/whats-on/circus-and-magic/2023/the-mirror.html',
      },
      venue: {
        text: 'Sydney Opera House',
      },
    },
    id: '7b0edb2f-0a2b-5b1a-91e1-7d5185daa94c',
  },
  {
    data: {
      end_date: '2023-06-11',
      geo: {
        latitude: 32.7857248,
        longitude: -79.9347971,
      },
      location: {
        text: 'Charleston, SC, USA',
      },
      show: 'out-of-chaos',
      start_date: '2023-06-07',
      ticket_link: {
        url: 'https://spoletousa.org/events/gravity-other-myths-out-of-chaos/',
      },
      venue: {
        text: 'Spoleto Festival ',
      },
    },
    id: 'bf0a3cc1-9bc1-59a4-8d13-6cc32272ad08',
  },
  {
    data: {
      end_date: '2023-01-19',
      geo: {
        latitude: 45.1721359,
        longitude: 5.7333544,
      },
      location: {
        text: 'Grenoble, France',
      },
      show: 'backbone',
      start_date: '2023-01-17',
      ticket_link: {
        url: 'https://www.mc2grenoble.fr/spectacle/backbone/2023-01-17/https://',
      },
      venue: {
        text: 'Maison da la Culture de Grenoble',
      },
    },
    id: '73188543-9ff9-57ac-ad1c-1ddb60562fd7',
  },
  {
    data: {
      end_date: '2023-01-10',
      geo: {
        latitude: 52.5240885,
        longitude: 13.4018619,
      },
      location: {
        text: 'Berlin, Germany',
      },
      show: 'a-simple-space',
      start_date: '2023-01-03',
      ticket_link: {
        url: 'https://chamaeleonberlin.com/en/shows/play/a-simple-space/',
      },
      venue: {
        text: 'Chamäleon Theatre',
      },
    },
    id: '7c86a0b4-d00e-529b-ab73-b8ec2be2ba6f',
  },
  {
    data: {
      end_date: '2022-12-16',
      geo: {
        latitude: 45.9883551,
        longitude: 4.7152495,
      },
      location: {
        text: 'Villefranche-sur-Saône, France',
      },
      show: 'a-simple-space',
      start_date: '2022-12-15',
      ticket_link: {
        url: 'https://theatredevillefranche.com/spectacles/a-simple-space/',
      },
      venue: {
        text: 'Théâtre de Villefranche',
      },
    },
    id: 'e2aeb7f2-a6af-5fc9-a14c-3a3722c10312',
  },
  {
    data: {
      end_date: '2022-12-06',
      geo: {
        latitude: 48.9677456,
        longitude: 2.4025473,
      },
      location: {
        text: 'Garges-lès-Gonesse, France',
      },
      show: 'a-simple-space',
      start_date: '2022-12-05',
      ticket_link: {
        url: null,
      },
      venue: {
        text: 'Espace Lino Ventura',
      },
    },
    id: '1e570702-6c05-5242-aab0-0ada9d5f8282',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 52.0244306,
        longitude: 5.5511496,
      },
      location: {
        text: 'Veenendaal, Netherlands',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-25',
      ticket_link: {
        url: 'https://www.lampegiet.nl/agenda/3423/Gravity_and_other_myths/Out_of_Chaos_https://',
      },
      venue: {
        text: 'Theater De Lampegiet',
      },
    },
    id: 'a808b348-f569-5fb5-ae30-7f0d8e8fc3cc',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 45.8342303,
        longitude: 3.0901194,
      },
      location: {
        text: 'Cébazat, France',
      },
      show: 'a-simple-space',
      start_date: '2022-11-22',
      ticket_link: {
        url: 'http://www.semaphore-cebazat.fr/la-saison/les-spectacles/397-a-simple-space?date=2022-11-22-20-30',
      },
      venue: {
        text: 'Sémaphore',
      },
    },
    id: '71e035bb-d489-5439-aff5-949319c17613',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 47.3523549,
        longitude: 0.642698,
      },
      location: {
        text: 'Joué-lès-Tours, France',
      },
      show: 'backbone',
      start_date: '2023-02-09',
      ticket_link: {
        url: 'https://espacemalraux.jouelestours.fr/saison-culturelle/spectacles-tout-public/backbone-compagnie-gravity-other-mythshttps://',
      },
      venue: {
        text: 'Espace Malraux',
      },
    },
    id: '33e17fdb-ccbd-5481-8aca-ec59949f0582',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 40.24985958285977,
        longitude: -111.64810889897542,
      },
      location: {
        text: 'Provo, UT',
      },
      show: 'a-simple-space',
      start_date: '2023-04-11',
      ticket_link: {
        url: 'https://arts.byu.edu/event/gravity-and-other-myths-a-simple-space/',
      },
      venue: {
        text: 'Brigham Young University, Harris Fine Arts Center',
      },
    },
    id: '8124352e-b320-561e-aaf8-e9085a110ea0',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 50.8497353,
        longitude: 4.2589221,
      },
      location: {
        text: 'Dilbeek, Belgium',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-15',
      ticket_link: {
        url: 'https://www.westrand.be/search?zoekwaarde=out+of+chaos',
      },
      venue: {
        text: 'Westrand Cultuurcentrum',
      },
    },
    id: '480a0e6c-ce2b-5b3b-ad00-c3e564dc8126',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 51.2131422,
        longitude: 4.2597822,
      },
      location: {
        text: 'Beveren, Belgium',
      },
      show: 'out-of-chaos',
      start_date: '2023-04-01',
      ticket_link: {
        url: 'https://www.tervesten.be/nl/programma/gravity-other-myths-au',
      },
      venue: {
        text: 'CC Ter Vesten',
      },
    },
    id: '8df38062-227b-5c8c-b8fb-3b7bc1f8eeb9',
  },
  {
    data: {
      end_date: '2023-05-11',
      geo: {
        latitude: 50.834651,
        longitude: -0.1885843,
      },
      location: {
        text: 'Brighton, UK',
      },
      show: 'out-of-chaos',
      start_date: '2023-05-09',
      ticket_link: {
        url: 'https://brightonfestival.org/whats-on/out-of-chaos-6162/',
      },
      venue: {
        text: 'Brighton Festival ',
      },
    },
    id: '6951fce3-7c83-5553-9c3c-4092ee0833d8',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 51.0759173,
        longitude: 4.7154134,
      },
      location: {
        text: 'Heist-op-den-Berg, Belgium',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-16',
      ticket_link: {
        url: 'https://www.zwaneberg.be/e2388/gravity-and-other-myths',
      },
      venue: {
        text: 'Cultuurcentrum Zwaneberg',
      },
    },
    id: '88c419e3-83ab-5040-a7d0-b86e0dfe8efa',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 48.3117612,
        longitude: 14.3094568,
      },
      location: {
        text: 'Linz, Austria',
      },
      show: 'out-of-chaos',
      start_date: '2023-04-30',
      ticket_link: {
        url: 'https://www.posthof.at/programm/programm/article/gravity-other-myths-aus-3/',
      },
      venue: {
        text: 'Posthof',
      },
    },
    id: '45ccdc58-951d-5ce7-bc67-4aadc186e147',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 51.1481557,
        longitude: 3.0223397,
      },
      location: {
        text: 'Brugge, Belgium',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-19',
      ticket_link: {
        url: 'https://www.ccbrugge.be/agenda/2832/Gravity_Other_Myths/Out_Of_Chaoshttps://',
      },
      venue: {
        text: 'Cultuurcentrum Brugge',
      },
    },
    id: '4aef6a8b-e076-57d8-ac59-8fcc0976166a',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 50.965199,
        longitude: 3.0497593,
      },
      location: {
        text: 'Roeselare, Belgium',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-18',
      ticket_link: {
        url: 'https://www.despil.be/agenda/1196/Gravity_and_other_myths/Out_of_chaoshttps://',
      },
      venue: {
        text: 'De Spil',
      },
    },
    id: '1a802f0c-7054-5343-940b-1b1037eac1f8',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 48.6476498,
        longitude: 4.9583034,
      },
      location: {
        text: 'Saint-Dizier, France',
      },
      show: 'backbone',
      start_date: '2023-01-14',
      ticket_link: {
        url: 'https://les3scenes.saint-dizier.fr/evenement/backbone-cirque-5905.htmlhttps://',
      },
      venue: {
        text: 'Les Fuseaux',
      },
    },
    id: '9be80aee-0835-5a59-9755-74506509010b',
  },
  {
    data: {
      end_date: '2023-02-04',
      geo: {
        latitude: 48.793441,
        longitude: 2.3629888,
      },
      location: {
        text: 'Villejuif, Île-de-France',
      },
      show: 'backbone',
      start_date: '2023-02-03',
      ticket_link: {
        url: 'https://trr.fr/spectacles/backbone/https://',
      },
      venue: {
        text: 'Romain Rolland Theater',
      },
    },
    id: '39511ad0-1a84-583f-b4f2-4a3ec72ed13b',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 45.933360699214106,
        longitude: 6.640141010284425,
      },
      location: {
        text: 'Sallanches, France',
      },
      show: 'backbone',
      start_date: '2023-01-21',
      ticket_link: {
        url: 'https://sallanchesmontblanc.com/vivre-sallanches/saison-culturelle-culturralhttps://',
      },
      venue: {
        text: 'Room Léon Curral',
      },
    },
    id: '1c1fa888-dace-5688-80d6-d939fe81d018',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 48.8360869,
        longitude: 2.2415863,
      },
      location: {
        text: 'Boulogne-Billancourt, France',
      },
      show: 'backbone',
      start_date: '2023-02-11',
      ticket_link: {
        url: 'https://indiv.themisweb.fr/0363/fListeManifs.aspx?idstructure=0363https://',
      },
      venue: {
        text: 'Carré Belle-Feuille',
      },
    },
    id: 'eee46726-449a-5f20-ba3f-45da5ba613b1',
  },
  {
    data: {
      end_date: '2023-02-25',
      geo: {
        latitude: 60.1622809,
        longitude: 24.906096,
      },
      location: {
        text: 'Helsinki, Finland',
      },
      show: 'out-of-chaos',
      start_date: '2023-02-24',
      ticket_link: {
        url: 'https://www.tanssintalo.fi/en/performances/gravity-other-myths-out-of-chaos',
      },
      venue: {
        text: 'Dance House',
      },
    },
    id: 'bdc0e594-24e4-5f8c-8a0c-231718b3c1a2',
  },
  {
    data: {
      end_date: '2022-11-27',
      geo: {
        latitude: 46.8090457,
        longitude: 1.6913667,
      },
      location: {
        text: 'Chateauroux, France',
      },
      show: 'a-simple-space',
      start_date: '2022-11-26',
      ticket_link: {
        url: 'https://www.equinoxe-chateauroux.fr/saisons/22-23/a-simple-space/',
      },
      venue: {
        text: 'Equinoxe',
      },
    },
    id: 'bc9bfe0c-bedd-5cde-a23a-f30dcf01ff94',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 51.324525,
        longitude: 4.9416123,
      },
      location: {
        text: 'Turnhout, Belgium',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-31',
      ticket_link: {
        url: 'https://www.warande.be/programma/6356/Gravity_Other_Myths_Australie_/Out_of_Chaos_8_https://',
      },
      venue: {
        text: 'Cultuurhuis De Warande',
      },
    },
    id: '8475bdfa-251f-585b-abe4-55631b162589',
  },
  {
    data: {
      end_date: '2022-12-03',
      geo: {
        latitude: 49.4189425,
        longitude: 2.0739993,
      },
      location: {
        text: 'Beauvais, France',
      },
      show: 'a-simple-space',
      start_date: '2022-12-02',
      ticket_link: {
        url: 'https://www.labatoude.fr/spectacle/simple-space/',
      },
      venue: {
        text: 'Salle Jacques Brel',
      },
    },
    id: '8fa3f8d4-8b83-58f7-9782-4b482e3fe3fc',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 49.3016342,
        longitude: -0.381965,
      },
      location: {
        text: 'Douvres-la-Délivrande, France',
      },
      show: 'a-simple-space',
      start_date: '2022-11-24',
      ticket_link: {
        url: 'https://www.c3lecube.fr/evenement/cirque/a-simple-space/329',
      },
      venue: {
        text: 'C³ - Le Cube',
      },
    },
    id: 'cbe6627a-9d93-55d2-9fe0-04b194442285',
  },
  {
    data: {
      end_date: '2023-02-16',
      geo: {
        latitude: 50.4137880834494,
        longitude: 4.442588268435489,
      },
      location: {
        text: 'Charleroi, Belgium',
      },
      show: 'backbone',
      start_date: '2023-02-15',
      ticket_link: {
        url: 'https://www.pba.be/spectacle/backbone/https://',
      },
      venue: {
        text: 'Palais des Beaux Arts de Charleroi',
      },
    },
    id: 'fae6b8ea-0049-59f1-943e-7b1dd708170b',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 48.99273,
        longitude: 2.44292,
      },
      location: {
        text: 'Gonesse, France',
      },
      show: 'a-simple-space',
      start_date: '2022-12-07',
      ticket_link: {
        url: null,
      },
      venue: {
        text: 'Espace Jacques Brel',
      },
    },
    id: '292ca90f-714e-5f41-b57a-d989d3a34253',
  },
  {
    data: {
      end_date: '2022-12-31',
      geo: {
        latitude: 52.5240885,
        longitude: 13.4018619,
      },
      location: {
        text: 'Berlin, Germany',
      },
      show: 'out-of-chaos',
      start_date: '2022-11-02',
      ticket_link: {
        url: 'https://chamaeleonberlin.com/en/shows/out-of-chaos/',
      },
      venue: {
        text: 'Chamäleon Theatre',
      },
    },
    id: '7d55325b-eae2-5437-a11d-9f7a093b93bf',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 49.40733475732093,
        longitude: 1.0519737587417313,
      },
      location: {
        text: 'Le Grand-Quevilly, France',
      },
      show: 'backbone',
      start_date: '2023-02-07',
      ticket_link: {
        url: 'https://https://billetterie-dullin-voltaire.mapado.com/en/event/95059-backbone-gravity-and-other-myths',
      },
      venue: {
        text: 'Théâtre Charles Dullin',
      },
    },
    id: 'c2ebe7ee-e59c-57cc-a109-13762f671595',
  },
  {
    data: {
      end_date: '2023-05-06',
      geo: {
        latitude: 47.3130504,
        longitude: 9.358271,
      },
      location: {
        text: 'Lustenau, Austria',
      },
      show: 'out-of-chaos',
      start_date: '2023-05-02',
      ticket_link: {
        url: 'https://freudenhaus.or.at/programm/2023/gravity-other-myths/',
      },
      venue: {
        text: 'Freudenhaus',
      },
    },
    id: '6716696d-ec17-5f74-bf6b-176011fd0805',
  },
  {
    data: {
      end_date: '2022-12-14',
      geo: {
        latitude: 46.315774,
        longitude: 4.8352785,
      },
      location: {
        text: 'Mâcon, France',
      },
      show: 'a-simple-space',
      start_date: '2022-12-13',
      ticket_link: {
        url: 'http://www.theatre-macon.com/2022-2023/gravity-and-others-myths',
      },
      venue: {
        text: 'Le Théâtre, scène nationale de Mâcon',
      },
    },
    id: '2b35cdef-489e-57fd-9ed0-dc7e37f250fb',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 49.0023023,
        longitude: 2.5193921,
      },
      location: {
        text: 'Roissy-en-France, France',
      },
      show: 'a-simple-space',
      start_date: '2022-11-29',
      ticket_link: {
        url: null,
      },
      venue: {
        text: "Centre culturel l'Orangerie",
      },
    },
    id: '7472baca-ed95-5d83-ac0d-dbe2c67be8d1',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 45.9256175,
        longitude: 6.8682058,
      },
      location: {
        text: 'Chamonix, France',
      },
      show: 'a-simple-space',
      start_date: '2022-12-18',
      ticket_link: {
        url: 'https://billetterie-chamonix.mapado.com/event/130822-a-simple-space',
      },
      venue: {
        text: 'Espace Michel Croz – EMC2',
      },
    },
    id: '3c5cffe5-0156-5160-a011-c85d8103c433',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 48.6945927,
        longitude: 2.4520596,
      },
      location: {
        text: 'Epinay-sous-Sénart, France',
      },
      show: 'a-simple-space',
      start_date: '2022-11-20',
      ticket_link: {
        url: 'https://billetterie.aggloculture.fr/selection/event/date?productId=10228575901428',
      },
      venue: {
        text: 'Maison des Arts et de la Culture',
      },
    },
    id: '9f857471-387a-5c7e-afe1-87a70e3edcbf',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 48.954792,
        longitude: 2.1862025,
      },
      location: {
        text: 'Fosses, France',
      },
      show: 'a-simple-space',
      start_date: '2022-12-08',
      ticket_link: {
        url: 'https://www.espacegerminal.fr/a-simple-space/',
      },
      venue: {
        text: 'Espace Germinal',
      },
    },
    id: '7dcb5eff-fbb5-5848-b03a-66c58bad6f91',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 52.0085207,
        longitude: 4.3605652,
      },
      location: {
        text: 'Delft, Netherlands',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-30',
      ticket_link: {
        url: 'https://www.theaterdeveste.nl/programma/8393/GRAVITY_OTHER_MYTHS/OUT_OF_CHAOS_https://',
      },
      venue: {
        text: 'Theater De Veste',
      },
    },
    id: 'a0e55c04-649a-5693-88ff-d3ceaf6fd451',
  },
  {
    data: {
      end_date: '2023-01-23',
      geo: {
        latitude: 57.7026985,
        longitude: 11.9688119,
      },
      location: {
        text: 'Göteborg, Sweden',
      },
      show: 'out-of-chaos',
      start_date: '2023-01-22',
      ticket_link: {
        url: 'https://storateatern.se/sv/program/gravity-and-other-myths/?date=2304220700https://',
      },
      venue: {
        text: 'Stora Teatern',
      },
    },
    id: '819e36b9-2fa7-5e26-9e28-80af597f7ed7',
  },
  {
    data: {
      end_date: '2023-03-02',
      geo: {
        latitude: 50.8845663,
        longitude: 3.430336,
      },
      location: {
        text: 'Waregem, Belgium',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-01',
      ticket_link: {
        url: 'https://www.ccdeschakel.be/voorstellingen/952/Out_of_Chaos/Gravity_Other_Mythshttps://',
      },
      venue: {
        text: 'Cultuurcentrum De Schakel',
      },
    },
    id: 'fb2ac3c5-161a-52ce-97ea-562380c295b5',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 46.8034529,
        longitude: 7.1535727,
      },
      location: {
        text: 'Fribourg, Switzerland',
      },
      show: 'backbone',
      start_date: '2023-01-29',
      ticket_link: {
        url: 'https://www.equilibre-nuithonie.ch/fr/spectacles/backbonehttps://',
      },
      venue: {
        text: 'Equilibre-Nuithonie',
      },
    },
    id: '2aa73046-5a7a-5941-9435-210beb92a9d6',
  },
  {
    data: {
      end_date: '2022-09-04',
      geo: {
        latitude: 48.1331997,
        longitude: 11.5798115,
      },
      location: {
        text: 'Munich, Germany',
      },
      show: 'a-simple-space',
      start_date: '2022-09-03',
      ticket_link: {
        url: 'https://www.superbloomfestival.com/',
      },
      venue: {
        text: 'Superbloom Festival',
      },
    },
    id: 'e3723041-daa2-5a16-a97e-0477b126275f',
  },
  {
    data: {
      end_date: '2022-07-31',
      geo: {
        latitude: 49.4864377,
        longitude: 8.394772,
      },
      location: {
        text: 'Ludwigshafen, Germany',
      },
      show: 'a-simple-space',
      start_date: '2022-07-29',
      ticket_link: {
        url: 'https://https://www.ludwigshafen.de/lebenswert/kulturbuero/internationales-strassentheaterfestival',
      },
      venue: {
        text: 'Internationales Straßentheaterfestival',
      },
    },
    id: 'e2e58d91-6619-504e-9077-3e05a3eb2d18',
  },
  {
    data: {
      end_date: '2022-08-13',
      geo: {
        latitude: 46.30839390572748,
        longitude: 7.4686587725507145,
      },
      location: {
        text: 'Crans-Montana, Switzerland',
      },
      show: 'a-simple-space',
      start_date: '2022-08-10',
      ticket_link: {
        url: 'https://cirqueausommet.ch/programme/a-simple-space/',
      },
      venue: {
        text: 'Festival Cirque au Sommet',
      },
    },
    id: '23cc84bf-3de4-5f3f-88e9-438c850619d0',
  },
  {
    data: {
      end_date: '2022-10-15',
      geo: {
        latitude: -35.2813148,
        longitude: 149.1302795,
      },
      location: {
        text: 'Canberra, ACT, Australia',
      },
      show: 'backbone',
      start_date: '2022-10-13',
      ticket_link: {
        url: 'https://canberratheatrecentre.com.au/show/backbone/',
      },
      venue: {
        text: 'The Playhouse',
      },
    },
    id: '9e14cf84-6dfb-58a2-a6e3-6d880455ed04',
  },
  {
    data: {
      end_date: '2022-11-18',
      geo: {
        latitude: 47.3944099,
        longitude: 8.0432426,
      },
      location: {
        text: 'Aarau, Switzerland',
      },
      show: 'a-simple-space',
      start_date: '2022-11-17',
      ticket_link: {
        url: null,
      },
      venue: {
        text: 'Alt Reithalle',
      },
    },
    id: '43a9c53e-227a-5603-b1b3-be0c91b2f1e3',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 55.956898,
        longitude: -3.185397,
      },
      location: {
        text: 'Edinburgh, Scotland',
      },
      show: 'macro',
      start_date: '2022-08-05',
      ticket_link: {
        url: 'https://www.eif.co.uk/events/macro',
      },
      venue: {
        text: 'Edinburgh International Festival',
      },
    },
    id: '9ec9a9c9-ca39-59cc-a3d3-fc86027ac5fe',
  },
  {
    data: {
      end_date: '2022-09-11',
      geo: {
        latitude: 55.7305852,
        longitude: 12.351968,
      },
      location: {
        text: 'Ballerup, Denmark',
      },
      show: 'backbone',
      start_date: '2022-09-08',
      ticket_link: {
        url: 'https://baltoppenlive.dk/arrangement/backbone8/',
      },
      venue: {
        text: 'Baltoppen Live',
      },
    },
    id: '0726467c-c786-59ab-8868-7c2f1c60372d',
  },
  {
    data: {
      end_date: '2022-09-01',
      geo: {
        latitude: 50.8880489,
        longitude: 5.9743472,
      },
      location: {
        text: 'Heerlen, Netherlands',
      },
      show: 'backbone',
      start_date: '2022-08-31',
      ticket_link: {
        url: 'https://cultura-nova.nl/en/voorstellingen/backbone/',
      },
      venue: {
        text: 'Festival Cultura Nova',
      },
    },
    id: 'e134a736-914d-5535-82bb-52f1cb1e8d72',
  },
  {
    data: {
      end_date: '2022-11-01',
      geo: {
        latitude: -31.429276,
        longitude: 152.9088935,
      },
      location: {
        text: 'Pt Macquarie, NSW, Australia',
      },
      show: 'backbone',
      start_date: '2022-10-31',
      ticket_link: {
        url: 'https://www.glasshouse.org.au/Whats-on/SELECT-Backbone-2022',
      },
      venue: {
        text: 'Glasshouse',
      },
    },
    id: 'f59af56c-b219-52c9-b4ac-049ae8903671',
  },
  {
    data: {
      end_date: '2022-08-09',
      geo: {
        latitude: 55.956898,
        longitude: -3.185397,
      },
      location: {
        text: 'Edinburgh, Scotland',
      },
      show: 'the-pulse',
      start_date: '2022-08-08',
      ticket_link: {
        url: 'https://www.eif.co.uk/events/the-pulse',
      },
      venue: {
        text: 'Edinburgh International Festival',
      },
    },
    id: '9f2969c4-b2ea-5bd1-aba9-b5090fa51713',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: -31.897444,
        longitude: 152.4942043,
      },
      location: {
        text: 'Taree, NSW, Australia',
      },
      show: 'backbone',
      start_date: '2022-10-29',
      ticket_link: {
        url: 'https://manning.sales.ticketsearch.com/sales/salesevent/51687',
      },
      venue: {
        text: 'Manning Entertainment Centre',
      },
    },
    id: '3ccd93c9-ba67-5427-bd53-50a5d5d360c2',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 38.341161,
        longitude: -0.484562,
      },
      location: {
        text: 'Alicante, Spain',
      },
      show: 'a-simple-space',
      start_date: '2022-07-15',
      ticket_link: {
        url: null,
      },
      venue: {
        text: '',
      },
    },
    id: '7c25dacb-e6f6-5c2d-a8a4-3a279cba7cde',
  },
  {
    data: {
      end_date: '2022-06-12',
      geo: {
        latitude: 64.1290927,
        longitude: -21.8961767,
      },
      location: {
        text: 'Reykjavík, Iceland',
      },
      show: 'a-simple-space',
      start_date: '2022-06-11',
      ticket_link: {
        url: 'https://www.listahatid.is/vidburdir/a-simple-space',
      },
      venue: {
        text: 'The Reykjavik City Theatre',
      },
    },
    id: '9fbdb1ad-28f8-5c44-9033-3927ecd25065',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: -33.4188631,
        longitude: 149.5765421,
      },
      location: {
        text: 'Bathurst, NSW, Australia',
      },
      show: 'backbone',
      start_date: '2022-10-18',
      ticket_link: {
        url: 'https://www.bmec.com.au/backbone.html',
      },
      venue: {
        text: 'Ian MacIntosh Theatre',
      },
    },
    id: '7100acb9-ec34-59e7-a8e7-62fea11fd422',
  },
  {
    data: {
      end_date: '2022-08-21',
      geo: {
        latitude: 48.8106345,
        longitude: 9.1768994,
      },
      location: {
        text: 'Stuttgart, Germany',
      },
      show: 'backbone',
      start_date: '2022-08-15',
      ticket_link: {
        url: 'https://www.theaterhaus.com/theaterhaus/index.php?id=1,3,22578',
      },
      venue: {
        text: 'Theaterhaus Stuttgart',
      },
    },
    id: '4d8985c6-e0b1-5c93-a464-2bbbbe1c4085',
  },
  {
    data: {
      end_date: '2022-10-26',
      geo: {
        latitude: -32.9272181,
        longitude: 151.7723622,
      },
      location: {
        text: 'Newcastle, NSW, Australia',
      },
      show: 'backbone',
      start_date: '2022-10-25',
      ticket_link: {
        url: 'https://civictheatrenewcastle.com.au/season-2022/season-2022-shows/backbone',
      },
      venue: {
        text: 'Newcastle Civic Theatre',
      },
    },
    id: '13e5ae84-afa3-51af-bde2-001b7bdb47da',
  },
  {
    data: {
      end_date: '2022-10-30',
      geo: {
        latitude: 52.5240917,
        longitude: 13.3996732,
      },
      location: {
        text: 'Berlin, Germany',
      },
      show: 'the-mirror',
      start_date: '2022-08-17',
      ticket_link: {
        url: 'https://chamaeleonberlin.com/en/shows/the-mirror',
      },
      venue: {
        text: 'Chamäleon Theater',
      },
    },
    id: '8b27c6e6-2c52-58d0-9885-510df13bbe96',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: -38.1461806,
        longitude: 145.1230722,
      },
      location: {
        text: 'Frankston, VIC, Australia',
      },
      show: 'backbone',
      start_date: '2022-11-12',
      ticket_link: {
        url: 'https://artscentre.frankston.vic.gov.au/Whats-On/Season-Shows/Backbone',
      },
      venue: {
        text: 'Frankston Arts Centre',
      },
    },
    id: 'b827eeb9-4e8f-5809-8d79-597c703f8776',
  },
  {
    data: {
      end_date: '2022-09-18',
      geo: {
        latitude: 51.2265871,
        longitude: 6.7721194,
      },
      location: {
        text: 'Dusseldorf, Germany',
      },
      show: 'backbone',
      start_date: '2022-09-16',
      ticket_link: {
        url: 'https://www.duesseldorf-festival.de/en/highlights/detail/addr/gravity-and-other-myths.html',
      },
      venue: {
        text: 'Dusseldorf Festival',
      },
    },
    id: '3558244b-872d-5997-8bdb-b600169617a4',
  },
  {
    data: {
      end_date: '2022-08-27',
      geo: {
        latitude: 53.7464772,
        longitude: -0.3355695,
      },
      location: {
        text: 'Hull, UK',
      },
      show: 'backbone',
      start_date: '2022-08-26',
      ticket_link: {
        url: 'https://www.freedomfestival.co.uk/',
      },
      venue: {
        text: 'Freedom Festival',
      },
    },
    id: 'e94b4ec4-8dc2-515a-9c15-fa5e14c455b9',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: -33.2813804,
        longitude: 151.4237613,
      },
      location: {
        text: 'Wyong, NSW, Australia',
      },
      show: 'backbone',
      start_date: '2022-10-22',
      ticket_link: {
        url: 'https://www.thearthousewyong.com.au/whats-on',
      },
      venue: {
        text: 'The Art House',
      },
    },
    id: 'c0584543-2cec-5911-b2a2-b4bed1b32a61',
  },
  {
    data: {
      end_date: '2022-11-10',
      geo: {
        latitude: -36.7544184,
        longitude: 144.2802755,
      },
      location: {
        text: 'Bendigo, VIC, Australia',
      },
      show: 'backbone',
      start_date: '2022-11-09',
      ticket_link: {
        url: 'https://www.bendigoregion.com.au/arts-culture-theatres/event/backbone',
      },
      venue: {
        text: 'Ulumbarra Theatre',
      },
    },
    id: '0e73b6f6-baf1-5dd0-99f8-4a3fc6d8dfeb',
  },
  {
    data: {
      end_date: '2023-04-19',
      geo: {
        latitude: 59.3193908,
        longitude: 17.8276604,
      },
      location: {
        text: 'Stockholm, Sweden',
      },
      show: 'out-of-chaos',
      start_date: '2023-04-18',
      ticket_link: {
        url: 'https://kulturhusetstadsteatern.se/cirkus/out-of-chaos',
      },
      venue: {
        text: 'Kulturhuset Stadsteatern',
      },
    },
    id: '09425dd4-d6b9-57c6-9784-cf7c83264411',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 50.8797872,
        longitude: 4.7026143,
      },
      location: {
        text: 'Leuven, Belgium',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-21',
      ticket_link: {
        url: 'https://www.30cc.be/nl/programma/item/out-of-chaos-1',
      },
      venue: {
        text: '30 CC Schouwburg',
      },
    },
    id: 'c5dcd70a-1d7e-58bd-b5b0-73bcdcfd0cf1',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 51.7635568,
        longitude: 5.5193452,
      },
      location: {
        text: 'Oss, Netherlands',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-28',
      ticket_link: {
        url: 'https://www.lievekamp.nl/programma/out-of-chaos-gravity-other-myths-28-mrt-20-15/',
      },
      venue: {
        text: 'Theater De Lievekamp',
      },
    },
    id: '840addbd-ef5d-52ab-8533-679d6cee80c1',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 51.0942246,
        longitude: 4.2299898,
      },
      location: {
        text: 'Bornem, Belgium',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-09',
      ticket_link: {
        url: 'https://www.terdilft.be/activiteitendetail/9052/out-of-chaos',
      },
      venue: {
        text: 'Cultuurcentrum Ter Dilft',
      },
    },
    id: '7860b735-3772-5d72-9a46-1dabf55b22ba',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 51.9808851,
        longitude: 5.9114165,
      },
      location: {
        text: 'Arnhem, Netherlands',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-29',
      ticket_link: {
        url: 'https://www.musisenstadstheater.nl/programma/out-of-chaos/6847',
      },
      venue: {
        text: 'Musis & Stadstheater Arnhem',
      },
    },
    id: '29484615-30cc-5554-87bd-287b158358aa',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 52.093076,
        longitude: 5.1251693,
      },
      location: {
        text: 'Utrecht, Netherlands',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-10',
      ticket_link: {
        url: 'https://stadsschouwburg-utrecht.nl/agenda/seizoen-2022-23/out-of-chaos/',
      },
      venue: {
        text: 'Stadsschouwburg Utrecht',
      },
    },
    id: '5f165745-33f1-5b00-9c09-099c4506bf9b',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 57.777666661725675,
        longitude: 14.171590124262769,
      },
      location: {
        text: 'Jönköping, Sweden',
      },
      show: 'out-of-chaos',
      start_date: '2023-04-26',
      ticket_link: {
        url: 'https://www.smot.se/out-of-chaos/',
      },
      venue: {
        text: 'Spira, Smålands Musik och Teater (SMOT)',
      },
    },
    id: '83b14609-732a-5abe-9ffb-1476f6ea12fe',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 63.8267604,
        longitude: 20.2643647,
      },
      location: {
        text: 'Umeå, Sweden',
      },
      show: 'out-of-chaos',
      start_date: '2023-04-21',
      ticket_link: {
        url: 'https://www.biljettcentrum.com/evenemang/out-of-chaos-gravity-other-myths/',
      },
      venue: {
        text: 'Idunteatern',
      },
    },
    id: '90909db4-7ec2-54a3-ad8d-78a3478443cf',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 62.3967242,
        longitude: 17.2863176,
      },
      location: {
        text: 'Sundsvall, Sweden',
      },
      show: 'out-of-chaos',
      start_date: '2023-04-15',
      ticket_link: {
        url: 'https://entresundsvall.se/arrangemang/out-of-chaos/',
      },
      venue: {
        text: 'Tonhallen',
      },
    },
    id: '1c157b11-c780-5da8-9ed4-8d01238d92e6',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 52.069545,
        longitude: 4.8750871,
      },
      location: {
        text: 'Hoogeveen, Netherlands',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-04',
      ticket_link: {
        url: 'https://detamboer.nl/programma/gravity-other-myths-out-of-chaos-2023',
      },
      venue: {
        text: 'De Tamboer',
      },
    },
    id: 'cc18d056-afb5-5394-873a-6880a1e1cd2a',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 57.188409,
        longitude: 14.0354415,
      },
      location: {
        text: 'Värnamo, Sweden',
      },
      show: 'out-of-chaos',
      start_date: '2023-04-25',
      ticket_link: {
        url: 'https://gummifabriken.nu/evenemang/out-of-chaos/',
      },
      venue: {
        text: 'Gummifabriken',
      },
    },
    id: 'b0a338a4-ef89-516f-9146-5cc7cf2fbf67',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 50.9884715,
        longitude: 5.0307259,
      },
      location: {
        text: 'Diest, Belgium',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-17',
      ticket_link: {
        url: 'https://www.ccdiest.be/e1715/out-of-chaos',
      },
      venue: {
        text: 'Cultuurcentrum Diest',
      },
    },
    id: '159ec247-287e-5b28-98d2-a2ab8523ed18',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 50.8867528,
        longitude: 5.9707839,
      },
      location: {
        text: 'Heerlen, Netherlands',
      },
      show: 'out-of-chaos',
      start_date: '2023-04-02',
      ticket_link: {
        url: 'https://www.plt.nl/programma/gravity-other-myths-aus-out-of-chaos/02-04-2023-15-30',
      },
      venue: {
        text: 'Parkstad Limburg Theaters',
      },
    },
    id: '7654d2a1-2d36-5097-b405-1009a9bb7809',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 52.159769908824025,
        longitude: 5.392765939231314,
      },
      location: {
        text: 'Amersfoort, Netherlands',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-03',
      ticket_link: {
        url: 'https://flint.nl/programma/seizoen-22-23/gravity-other-myths/',
      },
      venue: {
        text: 'De Flint',
      },
    },
    id: '06159d81-7bcf-5ce2-97f9-e308e41f3432',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 53.2126679,
        longitude: 6.5510615,
      },
      location: {
        text: 'Groningen, Netherlands',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-23',
      ticket_link: {
        url: 'https://www.spotgroningen.nl/programma/gravity-and-other-myths-3/',
      },
      venue: {
        text: 'SPOT Groningen',
      },
    },
    id: 'bb2e7d3d-5d3b-58a4-b308-be8cd096f1e6',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 51.5541941,
        longitude: 5.0806757,
      },
      location: {
        text: 'Tilburg, Netherlands',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-11',
      ticket_link: {
        url: 'https://www.schouwburgconcertzaaltilburg.nl/evenementen/37680/gravity-other-myths-out-of-chaos',
      },
      venue: {
        text: 'Schouwburg Concertzaal',
      },
    },
    id: '89fb3781-c431-5dd8-856e-8d833616abe5',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 43.07402552379599,
        longitude: -89.38799376280419,
      },
      location: {
        text: 'Madison, WI',
      },
      show: 'a-simple-space',
      start_date: '2023-05-06',
      ticket_link: {
        url: 'https://www.overture.org/tickets-events/2022-23-season/a-simple-space/',
      },
      venue: {
        text: 'Overture Center for the Arts',
      },
    },
    id: '63a2b3ee-8f21-5ec2-8fbe-49f95483159a',
  },
  {
    data: {
      end_date: '2023-04-21',
      geo: {
        latitude: 42.30700880476017,
        longitude: -85.5192400249418,
      },
      location: {
        text: 'Kalamazoo, MI',
      },
      show: 'a-simple-space',
      start_date: '2023-04-18',
      ticket_link: {
        url: 'https://www.efa-rep.org/gom',
      },
      venue: {
        text: 'Comstock Community Auditorium',
      },
    },
    id: '707417c2-0e3e-5e1f-9498-1b192b036118',
  },
  {
    data: {
      end_date: '2023-06-04',
      geo: {
        latitude: 49.26988,
        longitude: -123.1372312,
      },
      location: {
        text: 'Vancouver, Canada',
      },
      show: 'a-simple-space',
      start_date: '2023-05-30',
      ticket_link: {
        url: 'https://www.childrensfestival.ca/',
      },
      venue: {
        text: "Vancouver International Children's Festival",
      },
    },
    id: 'cb8a2968-b547-5ff3-b25f-2a8a7a419b59',
  },
  {
    data: {
      end_date: '2023-12-31',
      geo: {
        latitude: 48.8858866,
        longitude: 2.3697387,
      },
      location: {
        text: 'Paris, France',
      },
      show: 'a-simple-space',
      start_date: '2023-12-12',
      ticket_link: {
        url: 'https://lavillette.com/programmation/gravity-other-myths_e1748',
      },
      venue: {
        text: 'La Villette',
      },
    },
    id: '5236b6ec-2be0-51ce-9b79-9ee9e647356a',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 52.37787024994228,
        longitude: 4.625280336250777,
      },
      location: {
        text: 'Haarlem, Netherlands',
      },
      show: 'out-of-chaos',
      start_date: '2023-03-07',
      ticket_link: {
        url: 'https://www.theater-haarlem.nl/programma/18689/Out_of_Chaos_/Gravity_Other_Myths',
      },
      venue: {
        text: 'Stadsschouwburg Haarlem',
      },
    },
    id: '1316194f-b1ba-5d43-b4cf-3213de93068a',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 41.928572,
        longitude: -87.7142027,
      },
      location: {
        text: 'Chicago, IL',
      },
      show: 'a-simple-space',
      start_date: '2023-05-05',
      ticket_link: {
        url: 'https://aloftcircusarts.ticketleap.com/gravity-and-other-myths/dates',
      },
      venue: {
        text: 'ALOFT Circus Arts',
      },
    },
    id: '2e2b2963-6f3c-58fb-80e0-54a9c559d07e',
  },
  {
    data: {
      end_date: '2023-07-08',
      geo: {
        latitude: 45.5586459,
        longitude: -73.6162075,
      },
      location: {
        text: 'Montreal, Canada',
      },
      show: 'the-pulse',
      start_date: '2023-07-05',
      ticket_link: {
        url: 'https://montrealcompletementcirque.com/en/program/shows/the-pulse/',
      },
      venue: {
        text: 'TOHU',
      },
    },
    id: '34c35f99-7d01-5b2e-b2dc-316879fd6f09',
  },
  {
    data: {
      end_date: '2023-05-26',
      geo: {
        latitude: 43.638834,
        longitude: -79.3818785,
      },
      location: {
        text: 'Toronto, Canada',
      },
      show: 'a-simple-space',
      start_date: '2023-05-20',
      ticket_link: {
        url: 'https://harbourfrontcentre.com/event/a-simple-space/',
      },
      venue: {
        text: 'Harbourfront Centre',
      },
    },
    id: '13e02403-54d5-5f34-a49e-b8daae53f8bf',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 43.31285093574919,
        longitude: -91.80537493999591,
      },
      location: {
        text: 'Decorah, IA',
      },
      show: 'a-simple-space',
      start_date: '2023-04-15',
      ticket_link: {
        url: 'https://tickets.luther.edu/Online/default.asp?doWork::WScontent::loadArticle=Load&BOparam::WScontent::loadArticle::article_id=B9FBCE94-88CE-493F-B5BF-23A66868AD2C',
      },
      venue: {
        text: 'Luther College: Center for Faith and Life, Main Hall',
      },
    },
    id: 'cf49b0f1-6587-504e-aa47-a83bf69f1997',
  },
  {
    data: {
      end_date: '2023-07-01',
      geo: {
        latitude: 41.3695069,
        longitude: 2.1597372,
      },
      location: {
        text: 'Barcelona, Spain',
      },
      show: 'the-pulse',
      start_date: '2023-06-29',
      ticket_link: {
        url: 'https://www.barcelona.cat/grec/en/show/pulsehttps://',
      },
      venue: {
        text: 'Grec Fest',
      },
    },
    id: '0cd3a94e-69b4-5817-bde2-c16008381f17',
  },
  {
    data: {
      end_date: '2023-08-01',
      geo: {
        latitude: 47.0735683,
        longitude: 15.3717505,
      },
      location: {
        text: 'Graz, Austria',
      },
      show: 'the-pulse',
      start_date: '2023-07-28',
      ticket_link: {
        url: 'https://www.lastrada.at/project/thepulse',
      },
      venue: {
        text: 'La Strada Festival',
      },
    },
    id: '7169d7aa-8bd1-5f36-a8cb-a68563de2f6f',
  },
  {
    data: {
      end_date: null,
      geo: {
        latitude: 41.258887710047766,
        longitude: -95.93227720367064,
      },
      location: {
        text: 'Omaha, NE',
      },
      show: 'a-simple-space',
      start_date: '2023-04-14',
      ticket_link: {
        url: 'https://ticketomaha.com/Productions/gravity-and-other-myths_2023',
      },
      venue: {
        text: 'Holland Center at Omaha PAC',
      },
    },
    id: 'c1877c97-7ac9-5726-8806-160c98cdd181',
  },
  {
    data: {
      end_date: '2023-12-31',
      geo: {
        latitude: 52.5240885,
        longitude: 13.4018619,
      },
      location: {
        text: 'Berlin, Germany',
      },
      show: 'the-mirror',
      start_date: '2023-08-22',
      ticket_link: {
        url: 'https://chamaeleonberlin.com/en/',
      },
      venue: {
        text: 'Chamaleon Theatre',
      },
    },
    id: '2a96d327-dd9e-58eb-bf62-da902f83add7',
  },
];
