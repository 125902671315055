import React from 'react';
import { node, string } from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import Header from './Header';
import Footer from './Footer';
import '../styles/styles.scss';

type LayoutProps = {
  children: React.ReactNode | null;
  className: string;
};

const Layout = ({ children, className }: LayoutProps) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
              subLinks {
                name
                link
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <div className={`layout ${className}`}>
        <Header menuLinks={data.site.siteMetadata.menuLinks} />
        <main className="page-main">{children}</main>
        <Footer />
      </div>
    )}
  />
);

export default Layout;
