import React, { useState, useRef } from 'react';

const VideoLoop = ({ video }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [isVideoPlaying, setIsVideoPlaying] = useState(true);

  const handleToggleAnimation = () => {
    if (!videoRef.current) return;
    if (videoRef.current?.paused) {
      videoRef.current.play();
      setIsVideoPlaying(true);
    } else {
      videoRef.current.pause();
      setIsVideoPlaying(false);
    }
  };

  return (
    <div className="show-video-loop-container">
      <div className="show-video-loop-inner">
        <video className="video" ref={videoRef} playsInline muted autoPlay loop>
          <source src={video} type="video/mp4" />
        </video>
        <button className="pause-button" onClick={handleToggleAnimation}>
          {isVideoPlaying ? (
            <>
              <span className="button-text">Stop Video</span>
              <span className="pause-icon" />
            </>
          ) : (
            <>
              <span className="button-text">Start Video</span>
              <span className="play-icon" />
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default VideoLoop;
