import * as React from 'react';
import { graphql } from 'gatsby';
import TouringHistory from '../components/TouringHistory';

import { touringHistory } from '../data/touringHistory';
import assTouringHistory from '../data/assTouringHistory';
import bbTouringHistory from '../data/bbTouringHistory';
import oocTouringHistory from '../data/oocTouringHistory';
import pulseTouringHistory from '../data/pulseTouringHistory';
import { PrismicSeason, TouringHistory as TouringHistoryType } from '../types';
import { standardDateFormat } from '../utils/regex';

type PropTypes = {
  slice: {
    primary: {
      display_in_accordion: boolean;
      is_upcoming: boolean;
      show_filter: string;
      show_map: boolean;
    };
  };
};

const FILTER_TO_HISTORY_FILE_MAP = {
  'a-simple-space': assTouringHistory,
  backbone: bbTouringHistory,
  'out-of-chaos': oocTouringHistory,
  'the-pulse': pulseTouringHistory,
};

const sortSeasons = (season1: PrismicSeason, season2: PrismicSeason): number =>
  season1.data.start_date > season2.data.start_date ? 1 : -1;

/**
 * Converts date strings with a variety of formats into a consistent format
 * @param year
 * @param dateString date with formats like "2023-10-16", "16 Oct" or "Oct"
 * @returns The converted date and a boolean representing whether the date should be shown
 */
const convertDate = (
  year: number,
  dateString: string | undefined
): [Date | null, boolean] => {
  try {
    if (!dateString) {
      return [new Date(year), false];
    }

    if (standardDateFormat.test(dateString)) {
      return [new Date(dateString), true];
    }

    if (/\d/.test(dateString)) {
      return [new Date(`${dateString} ${year}`), true];
    }

    return [new Date(`01 ${dateString} ${year}`), false];
  } catch (error) {
    console.error(error);
    return [null, false];
  }
};

const convertDates = (
  year: number,
  startDate?: string,
  endDate?: string
): { end_date: string; start_date: string; showDate: boolean } => {
  try {
    const [startDateObj, showDate] = convertDate(year, startDate);
    if (!startDateObj) {
      throw new Error('Could not convert date');
    }

    const offset = startDateObj.getTimezoneOffset();
    const startDateWithOffset = new Date(
      startDateObj.getTime() - offset * 60 * 1000
    );

    const [endDateObj] = convertDate(year, endDate);
    const endDateWithOffset = endDateObj
      ? new Date(endDateObj.getTime() - offset * 60 * 1000)
      : '';

    return {
      start_date: startDateWithOffset.toISOString().split('T')[0],
      end_date: endDateWithOffset
        ? endDateWithOffset.toISOString().split('T')[0]
        : '',
      showDate: showDate,
    };
  } catch (error) {
    console.error(error);

    return {
      start_date: '',
      end_date: '',
      showDate: false,
    };
  }
};

const combineHistories = (showFilter: string): PrismicSeason[] => {
  const showTouringHistory = FILTER_TO_HISTORY_FILE_MAP[
    showFilter
  ] as TouringHistoryType;

  const prismicSeasons: PrismicSeason[] = touringHistory.filter(
    (season) => season.data.show === showFilter
  );

  if (!showTouringHistory) {
    return prismicSeasons.sort(sortSeasons);
  }

  const mappedShowTouringHistory: PrismicSeason[] = [];
  showTouringHistory.forEach((touringHistory) => {
    const { year, performances } = touringHistory;

    performances.forEach((season) => {
      mappedShowTouringHistory.push({
        data: {
          geo: season.latLng
            ? {
                latitude: season.latLng[0],
                longitude: season.latLng[1],
              }
            : undefined,
          location: {
            text: season.location,
          },
          show: season.show,
          ...convertDates(year, season.startDate, season.endDate),
          ticket_link: {
            url: season.ticketLink || '',
          },
          venue: {
            text: season.venue,
          },
        },
        id: '',
      });
    });
  });

  return [...mappedShowTouringHistory, ...prismicSeasons].sort(sortSeasons);
};

export const Seasons = ({ slice }: PropTypes) => {
  const showFilter = slice.primary.show_filter;
  const history = combineHistories(showFilter);

  return (
    <section className="prismic-section-contained">
      <h2>Touring History</h2>
      <TouringHistory history={history} />
    </section>
  );
};

export const query = graphql`
  fragment PageDataBodySeasons on PrismicPageDataBodySeasons {
    id
    slice_type
    primary {
      display_in_accordion
      is_upcoming
      show_filter
      show_map
    }
  }
`;
