import React, { Fragment } from 'react';
import { useState } from 'react';
import { Link } from 'gatsby';

import NavigationToggle from './NavigationToggle';
import SocialIcons from './SocialIcons';
import { MenuLinks } from '../types';

type NavigationMobileProps = {
  menuLinks: MenuLinks;
};

const NavigationMobile = (props: NavigationMobileProps) => {
  const { menuLinks } = props;

  const [navigationOpen, setNavigationOpen] = useState(false);

  const toggleNavigation = () => {
    setNavigationOpen((prev) => !prev);
  };

  return (
    <div className="nav-mobile">
      <NavigationToggle open={navigationOpen} onClick={toggleNavigation} />

      <div
        className={`nav-mobile__overlay ${navigationOpen ? 'visible' : ''}`}
        onClick={toggleNavigation}
      ></div>

      <div
        className={`nav-mobile__container ${navigationOpen ? 'visible' : ''}`}
      >
        <nav className="nav-mobile__nav">
          <ul className="navigation-items">
            {menuLinks.map((menuItem) => (
              <Fragment key={menuItem.link}>
                <li className="nav-mobile__item">
                  <Link to={menuItem.link} className="nav-mobile__link">
                    {menuItem.name}
                  </Link>
                </li>
                {menuItem.subLinks && (
                  <ul className="nav-mobile__sub-items">
                    {menuItem.subLinks.map((subLink) => (
                      <li className="nav-mobile__sub-item" key={subLink.link}>
                        <Link to={subLink.link} className="nav-mobile__link">
                          - {subLink.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </Fragment>
            ))}
          </ul>
        </nav>
        <SocialIcons />
      </div>
    </div>
  );
};

export default NavigationMobile;
