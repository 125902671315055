import React from 'react';
import { Link } from 'gatsby';

import NavigationDesktop from './NavigationDesktop';
import NavigationMobile from './NavigationMobile';
import LogoConnected from './svg/LogoConnected';
import { MenuLinks } from '../types';

type HeaderProps = {
  menuLinks: MenuLinks;
};

const Header = ({ menuLinks }: HeaderProps) => (
  <header className="header">
    <div className="header__logo-container">
      <Link to="/">
        <span className="visually-hidden">Home</span>
        <LogoConnected />
      </Link>
    </div>

    <NavigationMobile menuLinks={menuLinks} />
    <NavigationDesktop menuLinks={menuLinks} />
  </header>
);

export default Header;
