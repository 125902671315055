import * as React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { PrismicRichText } from '@prismicio/react';
import classnames from 'classnames';

type PropTypes = {
  slice: {
    primary: {
      image: {
        alt?: string;
        gatsbyImageData: IGatsbyImageData;
      };
      text: {
        richText: any;
      };
    };
    slice_label?: 'image-on-right' | 'center';
    slice_type: string;
  };
};

export const SpecialSection = ({ slice }: PropTypes) => {
  const isImageOnRight = slice.slice_label === 'image-on-right';
  const isCentered = slice.slice_label === 'center';

  const image = slice.primary.image.gatsbyImageData ? (
    <div className="image image--rounded">
      <GatsbyImage
        image={slice.primary.image.gatsbyImageData}
        alt={slice.primary.image.alt || ''}
      />
    </div>
  ) : null;

  return (
    <section className="section">
      {!isImageOnRight && image}

      <div
        className={classnames({
          ['text-container']: !isCentered,
          ['text-container--left']: isImageOnRight,
          ['text-container--right']: !isImageOnRight && !isCentered,
          ['central-section-content']: isCentered,
        })}
      >
        <PrismicRichText field={slice.primary.text.richText} />
      </div>

      {isImageOnRight && image}
    </section>
  );
};

export const query = graphql`
  fragment PageDataBodySpecialSection on PrismicPageDataBodySpecialSection {
    id
    slice_type
    primary {
      image {
        alt
        gatsbyImageData(
          width: 500
          height: 500
          layout: FIXED
          placeholder: NONE
        )
      }
      text {
        richText
      }
    }
    slice_label
  }
`;
