import React, { useMemo } from 'react';

import Accordion from './Accordion';
import Season from './Season';
import type { PrismicSeason } from '../types';

type YearDataType = {
  year: string;
  performances: PrismicSeason[];
};

type TouringHistoryProps = {
  history: PrismicSeason[];
};

const TouringHistory = (props: TouringHistoryProps) => {
  const { history } = props;

  const historySplitIntoYears = useMemo(() => {
    const years = new Set(
      history.map((season) => season.data.start_date.slice(0, 4))
    );

    const yearData: YearDataType[] = Array.from(years)
      .sort((yearA, yearB) => Number(yearA) - Number(yearB))
      .map((year) => {
        return {
          year,
          performances: history.filter((season) =>
            season.data.start_date.includes(year)
          ),
        };
      });
    return yearData;
  }, [history]);

  return (
    <div className="touring-history">
      {historySplitIntoYears.map((yearData) => (
        <Accordion
          key={yearData.year}
          buttonText={yearData.year}
          content={
            <ul className="dates">
              {yearData.performances.map((performanceData, i) => (
                <Season
                  key={i}
                  displayShowName={false}
                  displayTicketLink={false}
                  performanceData={performanceData}
                />
              ))}
            </ul>
          }
        />
      ))}
    </div>
  );
};

export default TouringHistory;
