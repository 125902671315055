import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

type SEOProps = {
  description?: string;
  lang?: string;
  keywords?: string[];
  title: string;
};

const SEO = (props: SEOProps) => {
  const { description, lang = 'en', keywords = [], title } = props;

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        const genericKeywords = [
          `circus`,
          `gravity`,
          `other`,
          `myths`,
          `arts`,
          `australia`,
          `adelaide`,
          `international`,
          `contemporary`,
          `performance`,
          `touring`,
          `aurora`,
          `nova`,
        ];
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ].concat(
              keywords.length > 0
                ? {
                    name: `keywords`,
                    content: genericKeywords.concat(keywords).join(`, `),
                  }
                : {
                    name: `keywords`,
                    content: genericKeywords.join(`, `),
                  }
            )}
          >
            <meta
              name="google-site-verification"
              content="aLr2pPvGxxQz95evs5HJtFZoGudYzpsDUCfG5Jc3YjM"
            />
          </Helmet>
        );
      }}
    />
  );
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
