import React from 'react';
import { Link } from 'gatsby';

import { MenuLinks } from '../types';

type NavigationDesktopProps = {
  menuLinks: MenuLinks;
};

const NavigationDesktop = ({ menuLinks }: NavigationDesktopProps) => {
  return (
    <nav className="nav-desktop">
      <ul className="nav-desktop__items">
        {menuLinks.map(
          (menuItem, i) =>
            menuItem.link !== '/' && (
              <li className="nav-desktop__item" key={i}>
                <Link to={menuItem.link} className="nav-desktop__link">
                  {menuItem.name}
                </Link>
                {menuItem.subLinks && (
                  <ul className="nav-desktop__sub-items">
                    {menuItem.subLinks.map((subMenuItem, j) => (
                      <li className="nav-desktop__sub-item" key={j}>
                        <Link
                          to={subMenuItem.link}
                          className="nav-desktop__link nav-desktop__link--sub-item"
                        >
                          {subMenuItem.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )
        )}
      </ul>
    </nav>
  );
};

export default NavigationDesktop;
