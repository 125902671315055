import { TouringHistory } from '../types';

const assTouringHistory: TouringHistory = [
  {
    year: 2013,
    performances: [
      {
        show: 'A Simple Space',
        startDate: 'Jan',
        location: 'Tasmanian, Australia',
        venue: 'Tasmanian Circus Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'March',
        location: 'Adelaide, Australia',
        venue: 'Adelaide Fringe Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'Aug',
        location: 'Edinburgh, Scotland',
        venue: 'Edinburgh Fringe Festival',
      },
    ],
  },
  {
    year: 2014,
    performances: [
      {
        show: 'A Simple Space',
        startDate: 'Feb',
        location: 'Adelaide, Australia',
        venue: 'Adelaide Fringe Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'Apr',
        location: 'Sydney, Australia',
        venue: 'HOOPLA Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Harare, Zimbabwe',
        venue: 'Harare International Festival of Arts',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Recklinghausen, Germany',
        venue: 'Ruhrfestspiele',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Charleston, USA',
        venue: 'SPOLETO Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'London, UK',
        venue: 'Udderbelly festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'Jul',
        location: 'Montreal, Canada',
        venue: 'Montreal Completement Cirque',
      },
      {
        show: 'A Simple Space',
        startDate: 'Aug',
        location: 'Edinburgh, Scotland',
        venue: 'Edinburgh Fringe Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'Sep',
        location: 'Ballerup, Denmark',
        venue: 'BALTOPPEN Live',
      },
      {
        show: 'A Simple Space',
        startDate: 'Oct',
        location: 'Seoul, South Korea',
        venue: 'HiSeoul Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'Nov',
        location: 'Hong Kong',
        venue: 'Freespace Festival',
      },
    ],
  },
  {
    year: 2015,
    performances: [
      {
        show: 'A Simple Space',
        startDate: 'Jan',
        location: 'Sydney, Australia',
        venue: 'Sydney Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'Feb',
        location: 'Adelaide, Australia',
        venue: 'Adelaide Fringe Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'March',
        location: 'Gothenburg, Sweden',
        venue: 'Stora Teatern',
      },
      {
        show: 'A Simple Space',
        startDate: 'March',
        location: 'Vara, Sweden',
        venue: 'Vara Konserthus',
      },
      {
        show: 'A Simple Space',
        startDate: 'April',
        location: 'Potsdam, Germany',
        venue: 'Fabrik',
      },
      {
        show: 'A Simple Space',
        startDate: 'April',
        location: 'Montreal, Canada',
        venue: 'TOHU',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'London, UK',
        venue: 'Udderbelly Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Buenos Aires, Argentina',
        venue: 'Polo Circo',
      },
      {
        show: 'A Simple Space',
        startDate: 'September',
        location: 'Christchurch, New Zealand',
        venue: 'Charles Luney Auditorium',
      },
      {
        show: 'A Simple Space',
        startDate: 'September',
        location: 'Mullumbimby, Australia',
        venue: 'Mullumbimby Circus Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'October',
        location: 'Vancouver, Canada',
        venue: 'The Cultch',
      },
      {
        show: 'A Simple Space',
        startDate: 'October',
        location: 'Irvine, CA, USA',
        venue: 'Irvine Barclay Theatre',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Germantown, MD, USA',
        venue: 'Blackrock Centre for the Arts',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Lewisburg, PA, USA',
        venue: 'Weis Centre for Performing Arts',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Lake Placid, NY, USA',
        venue: 'Lake Placid Centre for Performing Arts',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Raleigh, NC, USA',
        venue: 'Stewart Theatre',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Växjö, Sweden',
        venue: 'Växjö Konserthus',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Malmö, Sweden',
        venue: 'Malmö Live Konserthus',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Falköping, Sweden',
        venue: 'Falköpings Stadsteater',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Skövde, Sweden',
        venue: 'Skövde Kulturhus',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Eskilstuna, Sweden',
        venue: 'Eskilstuna Teater',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Linköping, Sweden',
        venue: 'Linköping Konsert & Kongress',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Stockholm, Sweden',
        venue: 'Södra Teatern',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Karlshamns, Sweden',
        venue: 'Karlshamns Stadsteater',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Östersund, Sweden',
        venue: 'Storsjöteatern',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Umeå, Sweden',
        venue: 'Folkets Hus',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Piteå, Sweden',
        venue: 'Nolia city Konferens',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Potsdam, Germany',
        venue: 'Fabrik',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Budapest, Hungary',
        venue: 'TRAFO',
      },
    ],
  },
  {
    year: 2016,
    performances: [
      {
        show: 'A Simple Space',
        startDate: 'January',
        location: 'Hong Kong',
        venue: 'Udderbelly Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'Feb',
        location: 'Australia',
        venue: 'Adelaide Fringe Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'April',
        location: 'Sao Paolo, Brazil',
        venue: 'Australia now Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'April',
        location: 'Mexico City, Mexico',
        venue: 'CENART',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Buenos Aires, Argentina',
        venue: 'Polo Circo',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Lima, Peru',
        venue: 'Parque de la Reserva',
      },
      {
        show: 'A Simple Space',
        startDate: 'June',
        location: 'Shanghai, China',
        venue: 'Xintiandi Fringe Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'June',
        location: 'Glastonbury, England',
        venue: 'Glastonbury Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'June',
        location: 'Kortrijk, Belgium',
        venue: 'Perplx Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'July',
        location: 'Collines, France',
        venue: 'Festival Des 7',
      },
      {
        show: 'A Simple Space',
        startDate: 'July',
        location: 'Munich, Germany',
        venue: 'Tollwood Summer Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'July',
        location: 'Milton Keynes, England',
        venue: 'Milton Keynes International Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'July',
        location: 'Galway, Ireland',
        venue: 'Galway International Arts Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'August',
        location: 'Edinburgh, Scotland',
        venue: 'Edinburgh Fringe Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'September',
        location: 'Zurich, Switzerland',
        venue: 'Theaterspektakel',
      },
      {
        show: 'A Simple Space',
        startDate: 'September',
        location: 'Lustenau, Austria',
        venue: 'Freudenhaus',
      },
      {
        show: 'A Simple Space',
        startDate: 'October',
        location: 'Fayetteville, AR, USA',
        venue: 'Inside, Outside, Upside Down Circus Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'October',
        location: 'Sarasota, FL, USA',
        venue: 'Ringling International Arts Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'October',
        location: 'Richmond, VA, USA',
        venue: 'Modlin Center For The Arts',
      },
      {
        show: 'A Simple Space',
        startDate: 'October',
        location: 'Vineland, NJ, USA',
        venue: 'Guarancini Performing Arts Center',
      },
      {
        show: 'A Simple Space',
        startDate: 'October',
        location: 'Germantown, MD, USA',
        venue: 'Blackrock Center For The Arts',
      },
      {
        show: 'A Simple Space',
        startDate: 'October',
        location: 'Shepherdstown, WV, USA',
        venue: 'Shepherdstown University',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Bethlehem, PA, USA',
        venue: 'Zoellner Arts Center',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Slippery Rock, PA, USA',
        venue: 'Slippery Rock University',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Pittsburgh, PA, USA',
        venue: 'August Wilson Theater Pittsburgh',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Erie, PA, USA',
        venue: 'Mercyhurst University Erie',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Frostburg, MD, USA',
        venue: 'Frostburg State University',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Montreal, Canada',
        venue: 'Centaur Theatre',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Salzburg, Austria',
        venue: 'Winterfest',
      },
    ],
  },
  {
    year: 2017,
    performances: [
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Leuven, Belgium',
        venue: 'Circusfestival',
      },
      {
        show: 'A Simple Space',
        startDate: 'June',
        location: 'Sibiu, Romania',
        venue: 'Sibiu International Theatre Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'July',
        location: 'Lyon, France',
        venue: 'Les Nuits De Fourvière',
      },
      {
        show: 'A Simple Space',
        startDate: 'July',
        location: 'Stuttgart, Germany',
        venue: 'Colours International Dance Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'July',
        location: 'Wolfsburg, Germany',
        venue: 'Autostadt Summer Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'August',
        location: 'George Town, Malaysia',
        venue: 'George Town Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'September',
        location: 'Mérida, Mexico',
        venue: 'Capital Americana de la Cultura',
      },
      {
        show: 'A Simple Space',
        startDate: 'October',
        location: 'Montpellier, France',
        venue: 'EPIC du domaine d’O',
      },
      {
        show: 'A Simple Space',
        startDate: 'October',
        location: 'Murcia, Spain',
        venue: 'Teatro Circo Murcia',
      },
      {
        show: 'A Simple Space',
        startDate: 'October',
        location: 'Madrid, Spain',
        venue: 'Teatro Circo Price',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Genk, Belgium',
        venue: 'Theater op de Markt',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Beveren, Belgium',
        venue: 'Cultureel Centrum Ter Vesten',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Blankenberge, Belgium',
        venue: 'Cultuurcentrum',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Roeselare, Belgium',
        venue: 'De Spil Cultuurcentrum',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Heist-op-den-Berg, Belgium',
        venue: 'Cultuurcentrum Zwaneberg',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Turnhout, Belgium',
        venue: 'Cultuurhuis De Warande',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Le Revest-les-Eaux, France',
        venue: 'Space Jean Paul Maurric',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Karlsruhe, Germany',
        venue: 'Kulturzentrum Tollhaus',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Montbéliard, France',
        venue: 'MA Scene Nationale',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Stuttgart, Germany',
        venue: 'Theaterhaus Stuttgart',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Graz, Austria',
        venue: 'Cirque Noël',
      },
    ],
  },
  {
    year: 2018,
    performances: [
      {
        show: 'A Simple Space',
        startDate: 'January',
        location: 'Melbourne,  Australia',
        venue: 'Arts Centre Melbourne',
      },
      {
        show: 'A Simple Space',
        startDate: 'January',
        location: 'Perth, Australia',
        venue: 'Fringe World',
      },
      {
        show: 'A Simple Space',
        startDate: 'February',
        location: 'Adelaide, Australia',
        venue: 'Adelaide Fringe Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'April',
        location: 'Bathurst, Australia',
        venue: 'Catapult Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'April',
        location: 'Versico, Switzerland',
        venue: 'Accademia Teatro Dimitri',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Rotterdam, Netherlands',
        venue: 'Circusstad',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Hergotenbosch, Netherlands',
        venue: 'Theater aan de Parade',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Eindhoven, Netherlands',
        venue: 'Parktheater',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Reunion Islands',
        venue: 'Leu Tempo Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Netherlands',
        venue: 'Stadsschouwburg Utrecht',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Drachten, Netherlands',
        venue: 'Schouwburg De Lawei',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Groningen, Netherlands',
        venue: 'De Oosterpoort',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Hardenberg, Netherlands',
        venue: '',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Delft, Netherlands',
        venue: 'Theater De Veste',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Meppel, Netherlands',
        venue: 'Schouwburg Ogterop',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Leiden, Netherlands',
        venue: 'Leidse Schouwburg',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Tilburg, Netherlands',
        venue: 'Theaters Tilburg',
      },
      {
        show: 'A Simple Space',
        startDate: 'August',
        location: 'Breckenridge, Colorado, USA',
        venue: 'Breckenridge Creative Arts',
      },
      {
        show: 'A Simple Space',
        startDate: 'August',
        location: 'Hull, UK',
        venue: 'Freedom Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'September',
        location: 'Wellington, NSW, Australia',
        venue: 'Wellington Arts & Sculpture Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'September',
        location: 'Riga, Latvia',
        venue: 'Mikhail Chekhov Riga Russian Theatre',
      },
      {
        show: 'A Simple Space',
        startDate: 'September',
        location: 'Vilnius, Lithuania',
        venue: 'New Circus Weekend',
      },
      {
        show: 'A Simple Space',
        startDate: 'September',
        location: 'Sandvika, Norway',
        venue: 'Bærum Kulturhus',
      },
      {
        show: 'A Simple Space',
        startDate: 'September',
        location: 'Nøtterøy Norway',
        venue: 'Nøtterøy Kulturhus',
      },
      {
        show: 'A Simple Space',
        startDate: 'September',
        location: 'Bølgen, Norway',
        venue: 'Bølgen Kulturhus',
      },
      {
        show: 'A Simple Space',
        startDate: 'October',
        location: 'Sydney, Australia',
        venue: 'Barangaroo Acrobat’s Academy',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Melbourne, Australia',
        venue: 'Lost Lands Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Mérignac, France',
        venue: 'Le Pin Galant',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Mulhouse, France',
        venue: 'La Filature',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Saumur, France',
        venue: 'La Closerie',
      },
      {
        show: 'A Simple Space',
        startDate: 'November',
        location: 'Grasse, France',
        venue: 'Theatre de Grasse',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Nîmes, France',
        venue: 'Théâtre de Nîmes',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Miramas, France',
        venue: 'Théâtre La Colonne',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Lillebonne, France',
        venue: 'Centre Juliobona',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Soissons, France',
        venue: 'Cité de la Musique et de la Danse',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Saint Dizier, France',
        venue: 'Les Fuseaux',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Cusset, France',
        venue: 'Le Théâtre',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Vaulx en Velin, France',
        venue: 'Centre Charlie Chaplin',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Villefontaine, France',
        venue: 'Théâtre du Vellein',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Mamer, Luxembourg',
        venue: 'Centre Culturel Mamer',
      },
      {
        show: 'A Simple Space',
        startDate: 'December',
        location: 'Hong Kong',
        venue: 'Tai Kwun – Centre for Heritage and Arts',
      },
    ],
  },
  {
    year: 2019,
    performances: [
      {
        show: 'A Simple Space',
        startDate: 'Jan',
        location: 'Athens, GA, USA',
        venue: 'UGA Performing Arts Center',
      },
      {
        show: 'A Simple Space',
        startDate: 'Jan',
        location: 'Asheville, NC, USA',
        venue: 'Diana Wortham Theatre',
      },
      {
        show: 'A Simple Space',
        startDate: 'Jan',
        location: 'Charlotte NC, USA',
        venue: 'Booth Playhouse',
      },
      {
        show: 'A Simple Space',
        startDate: 'Jan',
        location: 'Philadelphia PA, USA',
        venue: 'IPAY Showcase',
      },
      {
        show: 'A Simple Space',
        startDate: 'Jan',
        location: 'Derry, NH, USA',
        venue: 'Stockbridge Theatre',
      },
      {
        show: 'A Simple Space',
        startDate: 'Jan',
        location: 'Fairfield, CT, USA',
        venue: 'Quick Center for the Arts',
      },
      {
        show: 'A Simple Space',
        startDate: 'Feb',
        location: 'New York, NY, USA',
        venue: 'New Victory Theatre',
      },
      {
        show: 'A Simple Space',
        startDate: 'Feb',
        location: 'Concord, NH, USA',
        venue: 'Capitol Center for the Arts',
      },
      {
        show: 'A Simple Space',
        startDate: 'Apr',
        location: 'London, UK',
        latLng: [51.5074, 0.1278],
        venue: 'Underbelly Festival Southbank',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Norwich UK',
        latLng: [52.6309, 1.2974],
        venue: 'Norfolk & Norwich Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Valladolid, Spain',
        latLng: [41.6523, -4.7245],
        venue: 'LAVA',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Guadalajara, Mexico',
        latLng: [20.6597, -103.3496],
        venue: 'Festival Cultural De Mayo',
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Trutnov, Czech Republic',
        latLng: [50.5654, 15.9091],
        venue: 'Cirk-UFF',
      },
      {
        show: 'A Simple Space',
        startDate: 'Jun',
        location: 'Mexico City',
        latLng: [19.4326, -99.1332],
        venue: 'Teatro de la Ciudad Esperanza Iris',
      },
      {
        show: 'A Simple Space',
        startDate: 'July',
        location: 'Lienz, Austria',
        latLng: [46.8277, 12.7627],
        venue: 'Olala Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'July',
        location: 'Barcelos, Portugal',
        latLng: [41.5321, -8.6191],
        venue: 'Festival Vaudeville Rendez-Vous',
      },
      {
        show: 'A Simple Space',
        startDate: 'July',
        location: 'Guimarães, Portugal',
        latLng: [41.4425, -8.2918],
        venue: 'Festival Vaudeville Rendez-Vous',
      },
      {
        show: 'A Simple Space',
        startDate: 'July',
        location: 'Graz, Austria',
        latLng: [47.0707, 15.4395],
        venue: 'La Strada Graz',
      },
      {
        show: 'A Simple Space',
        startDate: 'Aug',
        location: 'Stuttgart, Germany',
        latLng: [48.7758, 9.1829],
        venue: 'Theaterhaus',
      },
      {
        show: 'A Simple Space',
        startDate: 'Aug',
        location: 'Budapest, Hungary',
        latLng: [47.4979, 19.0402],
        venue: 'Sziget Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'Aug',
        location: 'Lustenau, Germany',
        latLng: [47.4208, 9.6594],
        venue: 'Freudenhaus',
      },
      {
        show: 'A Simple Space',
        startDate: 'Aug',
        location: 'Aalst, Belgium',
        latLng: [50.9378, 4.041],
        venue: 'Cirk Aalst',
      },
      {
        show: 'A Simple Space',
        startDate: 'Sep',
        location: 'Singapore',
        latLng: [1.3521, 103.8198],
        venue: 'IPAC',
      },
      {
        show: 'A Simple Space',
        startDate: 'Sep',
        location: 'Seoul, South Korea',
        latLng: [37.5665, 126.978],
        venue: 'Ulsan Promenade Festival',
      },
      {
        show: 'A Simple Space',
        startDate: 'Oct',
        location: 'Shanghai, China',
        venue: 'Art Space for Kids',
      },
      {
        show: 'A Simple Space',
        startDate: 'Oct',
        location: 'Plovdiv, Bulgaria',
        latLng: [42.1354, 24.7453],
        venue: 'House Of Culture',
      },
      {
        show: 'A Simple Space',
        startDate: 'Oct',
        location: 'Zwolle, Netherlands',
        latLng: [52.5168, 6.083],
        venue: 'Theater De Spiegel',
      },
      {
        show: 'A Simple Space',
        startDate: 'Nov',
        location: 'Cranbrook, BC, Canada',
        latLng: [49.513, -115.7694],
        venue: 'Key City Theatre',
      },
      {
        show: 'A Simple Space',
        startDate: 'Nov',
        location: 'Sallanches, France',
        latLng: [45.9367, 6.6306],
        venue: 'Léon Curral Hall - Great Hall',
      },
      {
        show: 'A Simple Space',
        startDate: 'Nov',
        location: 'Enschede, Netherlands',
        latLng: [52.2215, 6.8937],
        venue: 'Wilminktheater',
      },
      {
        show: 'A Simple Space',
        startDate: 'Nov',
        location: 'Waregem, Belgium',
        latLng: [50.8869, 3.4232],
        venue: 'UiT in Vlaanderen',
      },
      {
        show: 'A Simple Space',
        startDate: 'Nov',
        location: 'Diest, Belgium',
        latLng: [50.9896, 5.0502],
        venue: 'CC Diest',
      },
      {
        show: 'A Simple Space',
        startDate: 'Nov',
        location: 'Helmond, Belgium',
        latLng: [51.4793, 5.657],
        venue: 'The Playhouse | Theater hall',
      },
      {
        show: 'A Simple Space',
        startDate: 'Nov',
        location: 'Basel, Switzerland',
        latLng: [47.5596, 7.5886],
        venue: 'Reithalle Basel',
      },
      {
        show: 'A Simple Space',
        startDate: 'Nov',
        location: 'Dilbeek, Belgium',
        latLng: [50.8465, 4.2621],
        venue: 'Westrand - Theater Hall',
      },
      {
        show: 'A Simple Space',
        startDate: 'Nov',
        location: 'Bornem, Belgium',
        latLng: [51.0996, 4.2405],
        venue: 'Cultuurcentrum Ter Dilft',
      },
      {
        show: 'A Simple Space',
        startDate: 'Dec',
        location: 'Oostende, Belgium',
        latLng: [51.2154, 2.9287],
        venue: 'De Grote Post cultural center',
      },
      {
        show: 'A Simple Space',
        startDate: 'Dec',
        location: 'Sarcelles, France',
        latLng: [48.9973, 2.3785],
        venue: 'Salle André Malraux',
      },
      {
        show: 'A Simple Space',
        startDate: 'Dec',
        location: 'Rungis, France',
        latLng: [48.7494, 2.3499],
        venue: 'Théâtre',
      },
      {
        show: 'A Simple Space',
        startDate: 'Dec',
        location: 'Saint Ouen, France',
        latLng: [48.9119, 2.3338],
        venue: 'Espace 1789 X2',
      },
      {
        show: 'A Simple Space',
        startDate: 'Dec',
        location: 'Lempdes, France',
        latLng: [45.7698, 3.1974],
        venue: 'La 2deuche',
      },
      {
        show: 'A Simple Space',
        startDate: 'Dec',
        location: 'Andrézieux Bouthéon, France',
        latLng: [45.5264, 4.2621],
        venue: 'Théâtre du Parc',
      },
      {
        show: 'A Simple Space',
        startDate: 'Dec',
        location: 'Rambouillet, France',
        latLng: [48.6439, 1.8291],
        venue: ' La lanterne',
      },
      {
        show: 'A Simple Space',
        startDate: 'Dec',
        location: 'Courbevoie, France',
        latLng: [48.9006, 2.2593],
        venue: 'Salle Camille Saint Saëns',
      },
      {
        show: 'A Simple Space',
        startDate: 'Dec',
        location: 'Vénissieux, France',
        latLng: [45.6996, 4.8845],
        venue: 'La Machinerie',
      },
    ],
  },
  {
    year: 2020,
    performances: [
      {
        show: 'A Simple Space',
        startDate: 'Jan',
        location: 'Perth, Australia',
        latLng: [-31.9505, 115.8605],
        venue: 'Perth Fringe',
      },
      {
        show: 'A Simple Space',
        startDate: 'Feb',
        location: 'Summerside, PE, Canada',
        venue: 'Harbourfront Theatre',
        latLng: [46.3934, -63.7902],
      },
      {
        show: 'A Simple Space',
        startDate: 'Mar',
        location: 'Adelaide, Australia',
        latLng: [-34.9285, 138.6007],
        venue: 'Adelaide Fringe',
      },
      {
        show: 'A Simple Space',
        startDate: 'Mar',
        location: 'Adelaide, Australia',
        venue: 'Womadelaide',
        latLng: [-34.9285, 138.6007],
      },
    ],
  },
  {
    year: 2021,
    performances: [
      {
        show: 'A Simple Space',
        startDate: 'Oct',
        location: 'Adelaide, Australia',
        venue: "Queen's Theatre",
        latLng: [-34.9285, 138.6007],
      },
      {
        show: 'A Simple Space',
        startDate: 'Nov',
        location: 'St.Pölten, Austria',
        venue: 'Festspielhaus',
        latLng: [48.13, 15.37],
      },
      {
        show: 'A Simple Space',
        startDate: 'Nov',
        location: 'Stuttgart, Germany',
        venue: 'Theaterhaus',
        latLng: [48.7758, 9.1829],
      },
      {
        show: 'A Simple Space',
        startDate: 'Nov',
        location: 'Prague, Czech Republic',
        venue: 'Jatka 78',
        latLng: [50.075539, 14.4378],
      },
      {
        show: 'A Simple Space',
        startDate: 'Dec',
        location: 'Potsdam, Germany',
        venue: 'Fabrik',
        latLng: [52.402695, 13.074518],
      },
      {
        show: 'A Simple Space',
        startDate: 'Dec',
        location: 'Aarau, Germany',
        venue: 'Alte Reithalle',
        latLng: [47.392347, 8.048365],
      },
    ],
  },
  {
    year: 2022,
    performances: [
      {
        show: 'A Simple Space',
        startDate: 'Jan',
        location: 'Karlsruhe, Germany',
        venue: 'Tollhaus',
        latLng: [49.004606, 8.43193],
      },
      {
        show: 'A Simple Space',
        startDate: 'Jan',
        location: 'Grand Quevilly, France',
        venue: '',
        latLng: [49.4118, 1.0414],
      },
      {
        show: 'A Simple Space',
        startDate: 'March',
        location: 'Mount Gambier, Australia',
        venue: 'Mount Gambier Showgrounds',
        latLng: [-37.839205, 140.799493],
      },
      {
        show: 'A Simple Space',
        startDate: 'April',
        location: 'Naracoorte, Australia',
        venue: 'Harmony Day Wish Festival',
        latLng: [-36.950234, 140.741118],
      },
      {
        show: 'A Simple Space',
        startDate: 'April',
        endDate: '',
        location: 'Keith, Australia',
        venue: 'Keith War Memorial Sports Centre',
        latLng: [-36.096976, 140.351008],
      },
      {
        show: 'A Simple Space',
        startDate: 'April',
        location: 'Burra, Australia',
        venue: 'Burra Town Hall',
        latLng: [-33.680623, 138.937103],
      },
      {
        show: 'A Simple Space',
        startDate: 'April',
        location: 'Karlsruhe, Germany',
        venue: 'Tollhaus',
        latLng: [49.0069, 8.4037],
      },
      {
        show: 'A Simple Space',
        startDate: 'April',
        location: 'Franconville, France',
        venue: 'Espace Saint-Exupéry',
        latLng: [48.986, 2.2296],
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        location: 'Saint-Genis-Pouilly, France',
        venue: '',
        latLng: [46.2437, 6.0251],
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        endDate: '2022-05-09',
        location: 'Arras, France',
        venue: 'TANDEM National Scene',
        ticketLink:
          'https://www.tandem-arrasdouai.eu/evenement/a-simple-space-gravity-other-myths',
        latLng: [50.29039, 2.773452],
      },
      {
        show: 'A Simple Space',
        startDate: 'May',
        endDate: '',
        location: 'Échirolles, France',
        venue: 'La Rampe Ponatiere',
        ticketLink:
          'http://www.larampe-echirolles.fr/tous-les-spectacles/item/a-simple-space',
        latLng: [45.149418, 5.718412],
      },
    ],
  },
];

export default assTouringHistory;
