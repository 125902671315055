import * as React from 'react';
import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import ImageGalleryComponent from '../components/ImageGallery';

type PropTypes = {
  slice: {
    id: string;
    slice_type: string;
    items: {
      image: {
        gatsbyImageData: IGatsbyImageData;
      };
      image_credit: {
        text: string;
      };
    }[];
  };
};

export const ImageGallery = ({ slice }: PropTypes) => {
  return (
    <section className="prismic-section-contained prismic-image-gallery">
      <ImageGalleryComponent
        images={slice.items.map((item) => ({
          gatsbyImageData: item.image.gatsbyImageData,
          credit: item.image_credit.text,
        }))}
      />
    </section>
  );
};

export const query = graphql`
  fragment PageDataBodyImageGallery on PrismicPageDataBodyImageGallery {
    id
    slice_type
    items {
      image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      image_credit {
        text
      }
    }
  }
`;
