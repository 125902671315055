import { TouringHistory } from '../types';

const oocTouringHistory: TouringHistory = [
  {
    year: 2019,
    performances: [
      {
        show: 'Out Of Chaos',
        startDate: '2019-02-27',
        endDate: '2019-03-06',
        location: 'Adelaide, Australia',
        venue: 'Adelaide Festival',
      },
      {
        show: 'Out Of Chaos',
        startDate: '2019-03-09',
        endDate: '2019-03-11',
        location: 'Tasmania, Australia',
        venue: '10 Days On The Island',
      },
      {
        show: 'Out Of Chaos',
        startDate: '2019-07-23',
        endDate: '2019-07-27',
        location: 'Galway, Ireland',
        latLng: [53.2707, -9.0568],
        venue: 'Galway International Arts Festival',
      },
      {
        show: 'Out Of Chaos',
        startDate: '2019-08-15',
        endDate: '2019-08-18',
        location: 'Groningen, Netherlands',
        latLng: [53.2194, 6.5665],
        venue: 'Noorderzon',
      },
      {
        show: 'Out Of Chaos',
        startDate: '2019-08-24',
        endDate: '2020-02-17',
        location: 'Berlin, Germany',
        latLng: [52.52, 13.405],
        venue: 'Chamaeleon Theatre',
      },
    ],
  },
];

export default oocTouringHistory;
