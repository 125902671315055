import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import ImageGalleryModal from './ImageGalleryModal';
import { GalleryImage } from '../types';

type ImageGalleryProps = {
  images: GalleryImage[];
};

const ImageGallery = (props: ImageGalleryProps) => {
  const { images } = props;
  const [imageGalleryOpen, setImageGalleryOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const handleImageClick = (currentImage) => {
    setImageGalleryOpen(true);
    setCurrentImage(currentImage);
  };

  const handleGalleryClose = () => {
    setImageGalleryOpen(false);
  };

  const prevImage = () => {
    const numImages = images.length;
    const nextImageNum = currentImage === 0 ? numImages - 1 : currentImage - 1;
    setCurrentImage(nextImageNum);
  };

  const nextImage = () => {
    const numImages = images.length;
    const nextImageNum = (currentImage + 1) % numImages;
    setCurrentImage(nextImageNum);
  };

  return (
    <div className="image-gallery">
      {images.map((image, i) => {
        if (!image) return null;
        return (
          <div key={i} onClick={() => handleImageClick(i)}>
            <GatsbyImage
              image={image.gatsbyImageData}
              className="thumbnail"
              alt={`Promo image. Photo credit: ${image.credit}`}
            />
          </div>
        );
      })}
      <ImageGalleryModal
        open={imageGalleryOpen}
        onClose={handleGalleryClose}
        nextImage={nextImage}
        prevImage={prevImage}
        images={images}
        currentImageIndex={currentImage}
      />
    </div>
  );
};

export default ImageGallery;
