import * as React from 'react';
import { graphql } from 'gatsby';

type PropTypes = {
  slice: {
    items: {
      quote: {
        text: string;
      };
      author: {
        text: string;
      };
    }[];
    slice_type: string;
  };
};

export const Quotes = ({ slice }: PropTypes) => {
  return (
    <section className="prismic-section-contained prismic-reviews-section">
      {slice.items.map((item) => {
        return (
          <figure key={item.quote.text}>
            <blockquote>{item.quote.text}</blockquote>
            <figcaption>{item.author.text}</figcaption>
          </figure>
        );
      })}
    </section>
  );
};

export const query = graphql`
  fragment PageDataBodyQuotes on PrismicPageDataBodyQuotes {
    id
    slice_type
    items {
      quote {
        text
      }
      author {
        text
      }
    }
  }
`;
