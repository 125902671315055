import * as React from 'react';
import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import AwardImagesComponent from '../components/AwardImages';

type PropTypes = {
  slice: {
    items: {
      image: {
        gatsbyImageData: IGatsbyImageData;
      };
    }[];
    slice_type: string;
  };
};

export const AwardsImages = ({ slice }: PropTypes) => {
  return (
    <section className="prismic-section-contained">
      <AwardImagesComponent
        images={slice.items.map((item) => item.image.gatsbyImageData)}
      />
    </section>
  );
};

export const query = graphql`
  fragment PageDataBodyAwardsImages on PrismicPageDataBodyAwardsImages {
    id
    slice_type
    items {
      image {
        gatsbyImageData(
          width: 200
          height: 100
          layout: CONSTRAINED
          placeholder: NONE
        )
      }
    }
  }
`;
