import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PrismicTeamMember as PrismicTeamMemberType } from '../types';

type PrismicTeamMemberProps = {
  member: PrismicTeamMemberType;
  handleMoreInfoClick: (member: PrismicTeamMemberType) => void;
};

const PrismicTeamMember = (props: PrismicTeamMemberProps) => {
  const { member, handleMoreInfoClick } = props;
  const onMoreInfoClick = (event) => {
    event.preventDefault();
    handleMoreInfoClick(member);
  };

  return (
    <div className="team-member">
      <GatsbyImage
        image={member.headshot.gatsbyImageData}
        className="avatar"
        alt={member.name.text}
      />
      <div className="member-info">
        <p className="member-name">{member.name.text}</p>
        <p className="member-position">{member.role.text}</p>
        <p className="member-more-info">
          <a onClick={onMoreInfoClick}>More Info</a>
        </p>
      </div>
    </div>
  );
};

export default PrismicTeamMember;
