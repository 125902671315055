import * as React from 'react';
import { graphql } from 'gatsby';

import VideoLoopComponent from '../components/VideoLoop';

type PropTypes = {
  slice: {
    items: {
      video: {
        url: string;
      };
    }[];
    slice_type: string;
  };
};

export const VideoLoop = ({ slice }: PropTypes) => {
  const video = slice.items.length > 0 ? slice.items[0].video?.url : '';
  if (!video) return null;

  return (
    <section className="prismic-section-contained">
      <VideoLoopComponent video={video} />
    </section>
  );
};

export const query = graphql`
  fragment PageDataBodyVideoLoop on PrismicPageDataBodyVideoLoop {
    id
    slice_type
    items {
      video {
        url
      }
    }
  }
`;
