import React from 'react';
import SocialIcons from './SocialIcons';
import SupporterLogos from './SupporterLogos';

const Footer = () => (
  <footer className="footer">
    <div className="social-icons-container">
      <SocialIcons />
    </div>
    <div className="acknowledgement-of-country">
      <p>
        We acknowledge the Traditional Custodians of the land upon which we make
        our work, the Kaurna people.
      </p>
      <p>
        We honour their Elders, past, present and emerging, and acknowledge that
        the Indigenous people are the first artists of Australia.
      </p>
      <p>It is our privilege to live and create on their country.</p>
    </div>
    <SupporterLogos />
  </footer>
);

export default Footer;
