import React from 'react';

import carnivalCinema from '../images/logos/cc.png';
import ipay from '../images/logos/ipay.png';
import mfi from '../images/logos/MFI.svg';
import sa from '../images/logos/sa.png';
import sacc from '../images/logos/sacc.png';
import rise from '../images/logos/rise-fund.svg';

const SupporterLogos = () => (
  <div className="supporter-logos">
    <p>Supported By</p>
    <div className="logo-container">
      <img
        src={mfi}
        alt="australian major festivals initiative logo"
        className="supporter-image"
      />
      <img src={sa} alt="south australian logos" className="supporter-image" />
      <img
        src={sacc}
        alt="south australian circus centre logo"
        className="supporter-image"
      />
      <img
        src={carnivalCinema}
        alt="carnival cinema logo"
        className="supporter-image"
      />
      <img src={ipay} alt="ipay logo" className="supporter-image" />
      <img src={rise} alt="Rise Fund" className="supporter-image" />
    </div>
  </div>
);

export default SupporterLogos;
