import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

export type LinkCardProps = {
  title: string;
  image: IGatsbyImageData;
  link?: string;
};

const LinkCard = ({ title, image, link }: LinkCardProps) => {
  const isLinkFullURL =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
      link || ''
    );

  if (isLinkFullURL) {
    return (
      <div className="link-card">
        <a href={link} className="link-card__link">
          <span className="link-card__title">{title}</span>
          <GatsbyImage image={image} className="link-card__image" alt="" />
        </a>
      </div>
    );
  }

  if (link) {
    return (
      <div className="link-card">
        <Link to={link} className="link-card__link">
          <span className="link-card__title">{title}</span>
          <GatsbyImage image={image} className="link-card__image" alt="" />
        </Link>
      </div>
    );
  }

  return (
    <div className="link-card">
      <div className="link-card__link">
        <span className="link-card__title">{title}</span>
        <GatsbyImage image={image} className="link-card__image" alt="" />
      </div>
    </div>
  );
};

export default LinkCard;
