import React from 'react';

type NavigationToggleProps = {
  open: boolean;
  onClick: () => void;
};

const NavigationToggle = ({ open, onClick }: NavigationToggleProps) => {
  return (
    <button
      className={`navigation-toggle ${open ? 'open' : ''}`}
      onClick={onClick}
    >
      <span className="visually-hidden">Menu</span>
      <span className="navigation-toggle__line line1"></span>
      <span className="navigation-toggle__line line2"></span>
      <span className="navigation-toggle__line line3"></span>
    </button>
  );
};

export default NavigationToggle;
