import { TouringHistory } from '../types';

const pulseTouringHistory: TouringHistory = [
  {
    year: 2021,
    performances: [
      {
        show: 'The Pulse',
        startDate: 'Feb',
        location: 'Adelaide, Australia',
        venue: 'Adelaide Festival',
        latLng: [-34.9285, 138.6007],
      },
      {
        show: 'The Pulse',
        startDate: 'Aug',
        location: 'Darwin, Australia',
        venue: 'The Playhouse, Darwin Entertainment Centre',
        latLng: [-12.4637, 130.8444],
      },
    ],
  },
  {
    year: 2022,
    performances: [
      {
        show: 'The Pulse',
        startDate: 'Jan',
        location: 'Sydney, Australia',
        venue: 'Sydney Festival',
        latLng: [-33.8688, 151.2093],
      },
    ],
  },
];

export default pulseTouringHistory;
