/**
 * Creates a date that's consistent regardless of the user's timezone
 * @param dateString Date string in the format yyyy-mm-dd
 * @returns Date object that contains the date
 */
export const createConsistentDate = (dateString: string): Date => {
  if (
    typeof dateString !== 'string' ||
    dateString.length !== 10 ||
    dateString[4] !== '-' ||
    dateString[7] !== '-'
  ) {
    throw new Error('Invalid date format');
  }

  const [year, month, day] = dateString.split('-');
  const date = new Date();
  date.setFullYear(parseInt(year));
  date.setMonth(parseInt(month) - 1);
  date.setDate(parseInt(day));

  return date;
};
